import React from "react";
import swal from "../Pages/Components/Swal";
import History from "./Routing/History";

const premiumAlert = ({popup, loginWithRedirect, loggedIn}) => {
  const buttons = {};
  if (!loggedIn)
    buttons.login = {
      text: "Login",
      value: 1,
    }
  buttons.buy = {
    text: "Get Premium Now",
    value: 2,
  }
  swal({
    title: "Get Premium or Premium Plus to enjoy:",
    buttons: buttons,
    content: <PremiumAlertContent popup={popup} loginWithRedirect={loginWithRedirect} />,
  }).then(r=>{
    if (r === 1) {
      loginWithRedirect()
    } else if (r === 2) {
      if (popup) {
        try {
          window.opener.parent.location.href = "/premium";
        } catch (e) {
          window.open("/premium");
        }
      } else {
        History.push("/premium");
      }
    }
  });
};

const PremiumAlertContent = () => {
  return (
    <div className="get-premium-modal">
      <ul>
        <li>
          No Interruptions from Fund Drives (Premium Plus) or Promotional
          Announcements (Premium)
        </li>
        <li>Song Skips</li>
        <li>Audiophile Fidelity</li>
        <li>Unlimited Access</li>
      </ul>
    </div>
  );
};

export default premiumAlert;
