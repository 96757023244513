import React from "react";
import CountryInput from "./CountryInput";
import Spinner from "../Spinner";

const Shipping = (props) => {
  return (
    <form onSubmit={props.onSubmit}>
      <div>
        <CountryInput {...props} />

        <div className="multiple-input-row">
          <label htmlFor="firstName">
            First Name
            <input
              required
              type="text"
              id="firstName"
              onChange={(e) =>
                props.setAddress({
                  ...props.address,
                  fname: e.target.value,
                })
              }
            />
          </label>
          <label htmlFor="LastName">
            Last Name
            <input
              required
              type="text"
              id="lastName"
              onChange={(e) =>
                props.setAddress({
                  ...props.address,
                  lname: e.target.value,
                })
              }
            />
          </label>
        </div>

        <label htmlFor="address1">
          Street Address Line One
          <input
            required
            type="text"
            id="address1"
            onChange={(e) =>
              props.setAddress({
                ...props.address,
                addr1: e.target.value,
              })
            }
          />
        </label>

        <label htmlFor="address2">
          Street Address Line Two (optional)
          <input
            type="text"
            id="address2"
            onChange={(e) =>
              props.setAddress({
                ...props.address,
                addr2: e.target.value,
              })
            }
          />
        </label>

        <div className="multiple-input-row">
          <label htmlFor="city">
            City
            <input
              required
              type="text"
              onChange={(e) =>
                props.setAddress({
                  ...props.address,
                  city: e.target.value,
                })
              }
            />
          </label>
          <label htmlFor="state">
            State/Province
            <input
              required
              type="text"
              onChange={(e) =>
                props.setAddress({
                  ...props.address,
                  state: e.target.value,
                })
              }
            />
          </label>
          <label htmlFor="zip">
            Zip/Postal Code
            <input
              required
              type="text"
              onChange={(e) =>
                props.setAddress({
                  ...props.address,
                  zip: e.target.value,
                })
              }
            />
          </label>
        </div>
      </div>

      <hr className="divider" />

      <button className="button-primary" disabled={props.loading}>
        {props.loading ? <Spinner /> : "Submit Donation"}
      </button>
      {props.error && <p className="form-error-message">{props.error}</p>}
    </form>
  );
};

export default Shipping;
