export default [
  [
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide1-miles-davis.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Miles Davis",
      channelDesc: "",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide2-christian-mcBride.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Christian McBride",
      channelDesc: "",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide3-chris-botti.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Chris Botti",
      channelDesc: "",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide4-herbie-hancock.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Herbie Hancock",
      channelDesc: "",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide5-chet-baker.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Chet Baker",
      channelDesc: "",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide6-sonny-rollins.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Sonny Rollins",
      subtitle: "Photo credit: John Abbott",
      channelDesc: "",
      color: "white"
    }
  ],
  [
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide5-chet-baker.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Chet Baker",
      channelDesc: "Mix #2",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide4-herbie-hancock.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Herbie Hancock",
      channelDesc: "Mix #2",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide1-miles-davis.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Miles Davis",
      channelDesc: "Mix #2",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide3-chris-botti.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Chris Botti",
      channelDesc: "Mix #2",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide6-sonny-rollins.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Sonny Rollins",
      channelDesc: "Mix #2",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/JG/jg-slide2-christian-mcBride.jpg",
      name: "Laid-Back Jazz",
      title: "Photo - Christian McBride",
      channelDesc: "Mix #2",
      color: "white"
    }
  ],
  [
    {
      original:
        "https://assets.jazzgroove.org/Home/Dreams/afterglow-beach-clouds-417211.jpg",
      name: "Dreams",
      title: " ",
      channelDesc: "The Softest Laid-Back Jazz For Sleep",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Dreams/Amazing_colorful_sunset.jpg",
      name: "Dreams",
      title: " ",
      channelDesc: "The Softest Laid-Back Jazz For Sleep",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Dreams/Mount-Bromo-Indonesia-Purple-Sunset-Wallpaper.jpg",
      name: "Dreams",
      title: " ",
      channelDesc: "The Softest Laid-Back Jazz For Sleep",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Dreams/purple-sunset-over-the-pier-40855-2560x1600.jpg",
      name: "Dreams",
      title: " ",
      channelDesc: "The Softest Laid-Back Jazz For Sleep",
      color: "white"
    },
    {
      original: "https://assets.jazzgroove.org/Home/Dreams/purple-sunset.jpg",
      name: "Dreams",
      title: " ",
      channelDesc: "The Softest Laid-Back Jazz For Sleep",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Dreams/Windmill-silhouetted-at-sunset-in-eastern-Colorado.jpg",
      name: "Dreams",
      title: " ",
      channelDesc: "The Softest Laid-Back Jazz For Sleep",
      color: "white"
    }
  ],
  [
    {
      original:
        "https://assets.jazzgroove.org/Home/Gems/gem-slide1-ella-fitzgerald.jpg",
      name: "Gems",
      title: "Photo - Ella Fitzgerald",
      channelDesc: "Vocal Standards",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Gems/gem-slide2-nat-king-cole.jpg",
      name: "Gems",
      title: "Photo - Nat King Cole",
      channelDesc: "Vocal Standards",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Gems/gem-slide3-ray-charles.jpg",
      name: "Gems",
      title: "Photo - Ray Charles",
      channelDesc: "Vocal Standards",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Gems/gem-slide4-diana-krall.jpg",
      name: "Gems",
      title: "Photo - Diana Krall",
      channelDesc: "Vocal Standards",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Gems/gem-slide5-nancy-wilson.jpg",
      name: "Gems",
      title: "Photo - Nancy Wilson",
      channelDesc: "Vocal Standards",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Gems/gem-slide6-michael-buble.jpg",
      name: "Gems",
      title: "Photo - Michael Buble",
      channelDesc: "Vocal Standards",
      color: "white"
    }
  ],
  [
    {
      original:
        "https://assets.jazzgroove.org/Home/Smooth/smoothjazzblue-slide1-sade-adu.jpg",
      name: "Smooth Jazz",
      title: "Photo - Sade",
      channelDesc: "The Smoothest Place On Earth",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Smooth/smoothjazzblue-slide2-kenny-g.jpg",
      name: "Smooth Jazz",
      title: "Photo - Kenny G",
      channelDesc: "The Smoothest Place On Earth",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Smooth/smoothjazzblue-slide3-boney-james.jpg",
      name: "Smooth Jazz",
      title: "Photo - Boney James",
      channelDesc: "The Smoothest Place On Earth",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Smooth/smoothjazzblue-slide4-george-benson.jpg",
      name: "Smooth Jazz",
      title: "Photo - George Benson",
      channelDesc: "The Smoothest Place On Earth",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Smooth/smoothjazzblue-slide5-kirk-whalum.jpg",
      name: "Smooth Jazz",
      title: "Photo - Kirk Whalum",
      channelDesc: "The Smoothest Place On Earth",
      color: "white"
    },
    {
      original:
        "https://assets.jazzgroove.org/Home/Smooth/smoothjazzblue-slide6-dave-koz.jpg",
      name: "Smooth Jazz",
      title: "Photo - Dave Koz",
      channelDesc: "The Smoothest Place On Earth",
      color: "white"
    }
  ]
];
