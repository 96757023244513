import React from "react";
import { Redirect, Route } from "react-router";
import queryString from "query-string";

const ChannelRedirect = (props) => (
    <Route
      render={(routeProps) => {
        let values = queryString.parse(routeProps.location.search);
        if (values.channel && values.channel.toLowerCase() === "christmas") {
          values.channel = "mix2";
        }
        if (values.channel && values.channel === props.channel) {
          return null;
        } else {
          values.channel = props.channel;
          return (
            <Redirect
              to={{
                pathname: routeProps.location.pathname,
                hash: routeProps.location.hash,
                search: queryString.stringify(values),
                state: routeProps.location.state,
              }}
            />
          );
        }
      }}
    />
  );

  export default ChannelRedirect;