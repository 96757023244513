import React, { Component } from "react";
import swal from "@sweetalert/with-react";
import customSwal from "../Swal";

class Gift extends Component {
  state = {
    info: false,
  };

  showInfo = () => {
    customSwal({
      title: this.props.name,
      className: "swal-modal--gift",
      buttons: {
        close: {
          text: "Close",
          value: 0,
        },
        choose: {
          text: "Select",
          value: 1,
        },
      },
      content: (
        <div>
          {this.props.meta_data && this.props.meta_data.images ? (
            <img
              src={this.props.meta_data.images[0].url}
              alt={this.props.name}
              className="item-image"
            />
          ) : (
            <div />
          )}
          <p className="item-description">{this.props.description}</p>
          {this.props.smallPrint ? (
            <p className="small-print">
              Special thanks to{" "}
              <a
                href="https://www.4imprint.com/"
                target="_blank"
                rel="noopener noreferrer">
                4imprint.com
              </a>{" "}
              for their support of The Jazz Groove.
            </p>
          ) : (
            ""
          )}
        </div>
      ),
    }).then((res) => {
      if (res) {
        try {
          if (this.props.meta_data.variants) {
            customSwal({
              title: "Select your size",
              buttons: false,
              content: (
                <div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.props.selectGift(
                        this.props.meta_data.variants[e.target[0].value]
                      );
                      swal.close();
                    }}>
                    <div className="select-wrapper swal-select">
                    <select>
                      {this.props.meta_data.variants.map((variant, index) => {
                        return (
                          <option key={index} value={index}>
                            {variant.name}
                          </option>
                        );
                      })}
                    </select>
                    </div>
                    <input type="submit" className="swal-input" />
                  </form>
                </div>
              ),
            });
          } else {
            this.props.selectGift();
          }
        } catch (e) {
          this.props.selectGift();
        }
      }
    });
  };

  render() {
    return (
      <div className={this.props.selected ? "gift selected" : "gift"}>
        {this.props.meta_data && this.props.meta_data.images ? (
          <img
            src={this.props.meta_data.images[0].url}
            alt={this.props.name}
            className="gift-image"
          />
        ) : (
          <img
            className="gift-placeholder"
            src="https://assets.jazzgroove.org/logo-circle-white1-no-org.png"
            alt="jazz groove logo"
          />
        )}

        <div>
          <h3 className="gift-title">{this.props.name}</h3>

          {this.props.monthly ? (
            (
              this.props.meta_data && this.props.meta_data.pricing
                ? this.props.meta_data.pricing.monthly
                : null
            ) ? (
              <h4 className="gift-price">
                $
                {(this.props.meta_data.pricing.monthly.minimum / 100).toFixed(
                  2
                )}{" "}
                monthly
              </h4>
            ) : null
          ) : (
              this.props.meta_data && this.props.meta_data.pricing
                ? this.props.meta_data.pricing.oneTime
                : null
            ) ? (
            <h4 className="gift-price">
              ${(this.props.meta_data.pricing.oneTime.minimum / 100).toFixed(2)}
            </h4>
          ) : null}

          {this.props.caption ? <h4>{this.props.caption}</h4> : ""}
          {this.props.is_shippable ? (
            <h5 className="tiny">Includes US Shipping</h5>
          ) : (
            ""
          )}
        </div>

        <div className="buttons">
          <button
            className={"info"}
            onClick={(e) => {
              e.stopPropagation();
              this.showInfo();
            }}>
            More Info
          </button>
          <button
            className={this.props.selected ? "choose selected" : "choose"}
            onClick={() => {
              try {
                if (this.props.meta_data.variants) {
                  customSwal({
                    title: "Select your size",
                    buttons: false,
                    content: (
                      <div>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.props.selectGift(
                              this.props.meta_data.variants[e.target[0].value]
                            );
                            swal.close();
                          }}>
                          <div className="select-wrapper swal-select">
                          <select>
                            {this.props.meta_data.variants.map(
                              (variant, index) => {
                                return (
                                  <option key={index} value={index}>
                                    {variant.name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                          </div>
                          <input type="submit" className="swal-input" />
                        </form>
                      </div>
                    ),
                  });
                } else {
                  this.props.selectGift();
                }
              } catch (e) {
                this.props.selectGift();
              }
            }}>
            {this.props.selected ? "Selected" : "Select"}
          </button>
        </div>
      </div>
    );
  }
}

export default Gift;
