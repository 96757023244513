import React, { useState } from "react";
import Title from "./Components/Title";
import Axios from "axios";
import Spinner from "./Components/Spinner";
import { HelpfulTips } from "Pages/ThankYou";
import { useAuth0 } from "react-auth0-spa";

const ClaimGift = (props) => {
  const [status, setStatus] = useState({
    error: false,
    message: "",
  });
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [hasAccount, setHasAccount] = useState(isAuthenticated);
  const [claimed, setClaimed] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <div className="page about">
      <Title value="Claim Gift" />
      <h1 className="title">Claim Gift</h1>
      {claimed ? (
        hasAccount || isAuthenticated ? (
          <div className="contained-content order-confirmation page">
            <HelpfulTips
              first_name={claimed.gift.gift_receiver.first_name}
              email={claimed.gift.gift_receiver.email}
            />
          </div>
        ) : (
          <div className="contained-content">
            <h1 className="title">
              {claimed.gift.gift_receiver.first_name}, Sign up or Sign in
            </h1>
            <section className="step-box">
              <header>
                <h2>Final Step:</h2>
                <h2>Sign up or Sign in</h2>
              </header>
              <button onClick={loginWithRedirect}>Click here to finish claiming your gift</button>
            </section>
          </div>
        )
      ) : (
        <div className="contained-content">
          <p className="body">
            Claim your gift to gain access to your Premium Plus Jazz Groove
            Plan.
          </p>
          <button
            className="standard claim"
            onClick={() => {
              setLoading(true);
              setStatus({ error: false, message: "" });
              Axios.post(process.env.REACT_APP_API_ENDPOINT + "/gift", {
                id: props.match.params.id,
                dev: process.env.REACT_APP_NODE_ENV === "dev",
              })
                .then((res) => {
                  setLoading(false);
                  setClaimed(res.data);
                  setHasAccount(hasAccount || res.data.hasOwnProperty("users"));
                })
                .catch((e) => {
                  setStatus({
                    error: true,
                    message: e.response.data.message,
                  });
                  setLoading(false);
                });
            }}
          >
            {loading ? <Spinner /> : "Claim Your Gift"}
          </button>
          {status.error && (
            <p className="form-error-message">{status.message}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ClaimGift;
