/* eslint-disable */
import { context } from "Context";
import Slider from "rc-slider";
import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga";
import { Link, withRouter } from "react-router-dom";
import Song from "./Song";
import {
  audioPlayer,
  currentMetadata,
  BLOCK,
  activeChannelId,
  currentEntry,
  setMaxQuality,
} from "AudioPlayer";
import sendLog from "Utils/Logger";
import ChannelSelector from "./ChannelSelector";
import premiumAlert from "Utils/PlayerAlert";
import { isPremiumPlus, isPremium } from "Utils/Account/Plan";
import { useAuth0 } from "react-auth0-spa";
import GearMenu from "./GearMenu";
import swal from "../Swal";
import {
  AUTH0_USER_METADATA_KEY,
  LOW_QUALITY,
  MID_QUALITY,
  HIGH_QUALITY,
} from "Utils/Constants";
import * as Sentry from "@sentry/react";
import { maxListenText } from "App";
import History from "Utils/Routing/History";

export const Gear = withRouter((props) => {
  return (
    <button
      className={"cog"}
      onClick={() => props.settings(props)}
      aria-label={"settings"}
    >
      <i className="fas fa-cog" />{" "}
    </button>
  );
});

const getCleanQuality = (quality) => {
  switch (quality) {
    case LOW_QUALITY:
      return "128k AAC Audio Quality";
    case MID_QUALITY:
      return "256k AAC Audio Quality";
    case HIGH_QUALITY:
      return "FLAC Audio Quality";
    default:
      return "";
  }
};

const Player = (props) => {

  const [volume, setVolume] = useState(1);
  const [mobileShow, setMobileShow] = useState(false);
  const [muted, setMuted] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [playStart, setPlayStart] = useState(-1);
  const [qualityPreference, setQualityPreference] = useState("");
  const Context = useContext(context);
  const { user, isAuthenticated, loginWithRedirect, loading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user && user.plan && user[AUTH0_USER_METADATA_KEY].preferences && user[AUTH0_USER_METADATA_KEY].preferences.quality) {
      setMaxQuality(
        isPremium(user.plan)
          ? user[AUTH0_USER_METADATA_KEY].preferences.quality
          : LOW_QUALITY
      );
      setQualityPreference(
        isPremium(user.plan)
          ? user[AUTH0_USER_METADATA_KEY].preferences.quality
          : LOW_QUALITY
      );
    } else {
      setQualityPreference(LOW_QUALITY);
    }
  }, [isAuthenticated, user]);

  useEffect(()=>{
    function handleResize() {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePlay = () => {
    if (!Context.external) {
      try {
        if (!Context.paused) {
          pause();
        } else {
          play();
        }
      } catch (e) {
        Sentry.captureException(e);
        if (Context.activeChannel === 1) window.open("/jazzgroove-mix-2.m3u");
        else window.open("/jazzgroove-mix-1.m3u");
      }
    } else {
      if (Context.activeChannel === 1) window.open("/jazzgroove-mix-2.m3u");
      else window.open("/jazzgroove-mix-1.m3u");
    }
  };

  const play = () => {
    if (!audioPlayer) return;
    if (BLOCK) {
      swal({
        title: maxListenText,
        content: (
          <button className="standard" onClick={() => loginWithRedirect()}>
            Already donated? SIGN IN to your account.
          </button>
        ),
        buttons: {
          learn: "Learn more",
          premium: "Get Premium now",
          donate: "Donate",
        },
      }).then((value) => {
        switch (value) {
          case "learn":
          case "premium":
            History.push("/premium");
            break;
          case "donate":
            window.open("/checkout", "_blank");
            break;
          default:
        }
      });
    }
    audioPlayer.play();
    currentMetadata.endTime = null;
    sendLog("play", currentMetadata);
    ReactGA.event({
      category: "player",
      action: "play",
      label: activeChannelId,
    });
    ReactGA.event({
      category: "player",
      action: "streamquality",
      label: activeChannelId,
      value: isAuthenticated
        ? user && user[AUTH0_USER_METADATA_KEY].preferences && user[AUTH0_USER_METADATA_KEY].preferences.quality ? user[AUTH0_USER_METADATA_KEY].preferences.quality : LOW_QUALITY
        : LOW_QUALITY,
    });
    setPlayStart(parseInt(new Date().getTime() / 1000));
  };

  const pause = () => {
    if (!audioPlayer) return;
    if (!Context.paused) {
      audioPlayer.pause();
      currentMetadata.endTime = new Date().toISOString();
      sendLog("pause", currentMetadata);
    }
    setPlayStart(-1);
  };

  const skipSong = async () => {
    if (!audioPlayer) return;
    ReactGA.event({
      category: "player",
      action: "skipattempt",
      label: activeChannelId,
      value:
        currentEntry &&
        currentEntry.Asset &&
        currentEntry.Asset.tags &&
        currentEntry.Asset.tags.TITLE
          ? currentEntry.Asset.tags.TITLE +
            " - " +
            currentEntry.Asset.tags.ARTIST
          : "",
    });
    try {
      if (
        (isAuthenticated && !isPremium(user.plan)) ||
        (!isPremiumPlus(user.plan) &&
          currentEntry &&
          currentEntry.Asset &&
          currentEntry.Asset.tags &&
          currentEntry.Asset.tags["jg-voiceover"] === "FD")
      ) {
        premiumAlert({
          popup: Context.popup,
          loginWithRedirect: loginWithRedirect,
          loggedIn: isAuthenticated,
        });
      } else if (isPremium(user.plan)) {
        let skips = localStorage.getItem("skips");
        let date;
        if (skips) {
          skips = JSON.parse(skips);
          if (
            skips.count[Context.activeChannel] === 6 &&
            skips.resetTime > new Date().getTime()
          ) {
            swal(
              "Skip up to six tracks an hour, on each channel, as per music licensing guidelines."
            );
          } else {
            if (skips.resetTime < new Date().getTime()) {
              date = new Date();
              date.setHours(date.getHours() + 1, 0, 0, 0);
              skips.resetTime = date.getTime();
              skips.count = [0, 0, 0, 0, 0];
              skips.count[Context.activeChannel] = 1;
            } else {
              skips.count[Context.activeChannel] =
                skips.count[Context.activeChannel] + 1;
            }
            skip();
          }
        } else {
          date = new Date();
          date.setHours(date.getHours() + 1, 0, 0, 0);
          skips = {
            count: [0, 0, 0, 0, 0],
            resetTime: date.getTime(),
          };
          skips.count[Context.activeChannel] = 1;
          skip();
        }
        localStorage.setItem("skips", JSON.stringify(skips));
        return true;
      } else
        premiumAlert({
          popup: Context.popup,
          loginWithRedirect: loginWithRedirect,
          loggedIn: isAuthenticated,
        });
    } catch (e) {
      premiumAlert({
        popup: Context.popup,
        loginWithRedirect: loginWithRedirect,
        loggedIn: isAuthenticated,
      });
    }
    return false;
  };

  const skip = () => {
    if (!audioPlayer) return;
    currentMetadata.endTime = new Date().toISOString();
    sendLog("skip", currentMetadata);
    audioPlayer.seekQueueRelative(1);
    ReactGA.event({
      category: "player",
      action: "skipsuccess",
      label: activeChannelId,
      value: currentEntry.Asset.tags.TITLE
        ? currentEntry.Asset.tags.TITLE + " - " + currentEntry.Asset.tags.ARTIST
        : "",
    });
  };

  async function settings() {
    let returnValue = {};
    swal({
      title: "Audio Settings",
      content: (
        <GearMenu
          popup={Context.popup}
          external={Context.external}
          returnValue={returnValue}
          setReturnValue={(value) => (returnValue = value)}
          isAuthenticated={isAuthenticated}
          loginWithRedirect={loginWithRedirect}
          user={user}
          qualityPreference={qualityPreference}
        />
      ),
    }).then(() => {
      if (
        returnValue.hasOwnProperty("external") &&
        returnValue.external !== Context.external
      ) {
        Context.setExternal(returnValue.external);
        pause();
      }
      if (returnValue.quality && returnValue.quality !== qualityPreference) {
        setQualityPreference(returnValue.quality);
        setMaxQuality(returnValue.quality);
      }
    });
  }

  return (
    <aside
      id={"player"}
      className={props.match.path === "/stream" || mobileShow ? "mobile" : null}
    >
      <div
        className="inner-container"
        style={mobileShow ? { height: windowHeight } : { height: "100vh" }}
      >
        <Link to="/">
          <img
            className="logo"
            src="https://assets.jazzgroove.org/updated-logo-white.svg"
            alt="jazz groove logo"
          />
        </Link>
        <ChannelSelector
          channels={Context.channels}
          external={Context.external}
          activeChannel={Context.activeChannel}
          setActiveChannel={Context.setActiveChannel}
          showChannel={true}
          below={true}
        />
        <Song {...Context.songData} />

        <div>
          <button
            className={"full listen-button"}
            onClick={() => {
              if (!loading) handlePlay();
            }}
            aria-label="Play"
          >
            {Context.paused ? (loading ? "Loading..." : "Play") : "Pause"}
            {!loading &&
              (Context.paused ? (
                <i className="fas fa-play" />
              ) : (
                <i className="fas fa-pause" />
              ))}
          </button>

          <button
            className={"skip"}
            aria-label="Skip song"
            onClick={skipSong}
            style={Context.external ? { display: "none" } : {}}
          >
            Skip Song <i className="fas fa-step-forward" />
          </button>

          <div className="secondary-buttons">
            <Gear settings={settings} />

            {Context && [
              <button
                key="mute"
                className="volume-button"
                aria-label="Change volume"
                onClick={() => {
                  if (muted) {
                    audioPlayer.volume = volume;
                  } else {
                    audioPlayer.volume = 0;
                  }
                  setMuted((m) => !m);
                }}
              >
                {muted ? (
                  <i className="fas fa-volume-mute" />
                ) : (
                  <i className="fas fa-volume-up" />
                )}
              </button>,

              <div className="volume-slider" key="volume">
                <Slider
                  min={0}
                  step={0.00001}
                  max={1}
                  value={muted ? 0 : volume}
                  onChange={(value) => {
                    audioPlayer.volume = value;
                    setVolume(value);
                    setMuted(false);
                  }}
                />
              </div>,
            ]}
          </div>
        </div>

        <div
          className="bottom-links"
          style={
            props.match.path === "/stream" || mobileShow
              ? { display: "none" }
              : { display: "flex" }
          }
        >
          <a
            className="icon"
            target="_blank"
            rel="noopener noreferrer"
            href={"https://www.facebook.com/TheJazzGroove"}
            aria-label="Jazz Groove Facebook"
          >
            <i className="fab fa-facebook" />
          </a>
          <Link
            to={"/stream"}
            className="popup"
            onClick={(e) => {
              e.preventDefault();
              pause();
              ReactGA.event({
                category: "player",
                action: "popup",
              });
              window.open(
                "/stream" + props.location.search,
                "stream",
                "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=700,left=100,top=100"
              );
            }}
          >
            Open in Popup <i className="fas fa-external-link-alt" />
          </Link>
        </div>

        <button
          className="close"
          onClick={() => setMobileShow((m) => !m)}
          style={props.match.path === "/stream" ? { display: "none" } : {}}
          aria-label="Close player"
        >
          <i className="fas fa-times" />
        </button>
      </div>
      <div className="bottom-bar">
        <Song
          mobile={true}
          handlePlay={handlePlay}
          skipSong={skipSong}
          paused={Context.paused}
          {...Context.songData}
        />
        <div
          className="touch-overlay"
          onClick={() => setMobileShow((m) => !m)}
        />
      </div>
    </aside>
  );
};

export default withRouter(Player);
