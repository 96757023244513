import React from "react";
import Axios from "axios";

const Contact = (props) => {
  return (
    <div className="contact-section">
      <div className="multiple-input-row">
        <label htmlFor="fName">
          First Name
          <input
            type="text"
            id="fName"
            defaultValue={props.customer.first_name}
            onChange={(e) =>
              props.setCustomer({
                ...props.customer,
                first_name: e.target.value,
              })
            }
            required
          />
        </label>
        <label htmlFor="lName">
          Last Name
          <input
            type="text"
            id="lName"
            defaultValue={props.customer.last_name}
            onChange={(e) =>
              props.setCustomer({
                ...props.customer,
                last_name: e.target.value,
              })
            }
            required
          />
        </label>
      </div>
      <label htmlFor="email">
        Email
        <input
          type="email"
          id="email"
          defaultValue={props.customer.email}
          onChange={(e) =>
            props.setCustomer({ ...props.customer, email: e.target.value.toLowerCase() })
          }
          onBlur={(e) => {
            const email = e.target.value.toLowerCase();
            /*eslint-disable-next-line no-useless-escape*/
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              Axios.post(
                process.env.REACT_APP_API_ENDPOINT + "/checkout/analytics",
                {
                  email: email,
                }
              );
            }
          }}
          required
        />
      </label>
    </div>
  );
};

export default Contact;
