import React from "react";
import Title from "../Components/Title";
import { Link } from "react-router-dom";
import { useAuth0 } from "react-auth0-spa";

const SonosLander = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="page sonos">
      <Title value="Sonos" />

      <main className="sonos-lander">
        <section className="hero">
          <img
            src="https://assets.jazzgroove.org/Logo-white.svg"
            alt="The Jazz Groove"
            className="logo"
          />
          <img
            src="https://assets.jazzgroove.org/sonos/Sonos_Logo.png"
            alt="Sonos"
            style={{background: "white"}}
            className="logo"
          />
          <h1>Non-commercial Jazz.</h1>
          <h2>Listener-supported.</h2>
          <button className="free-account-button" onClick={()=>loginWithRedirect({ screen_hint: "signup", redirect_uri: process.env.REACT_APP_DOMAIN + "/link/sonos/complete" })}>
            Create free account
          </button>
          <p className="button-subtext">Listen for free</p>
        </section>

        <section className="sub-hero">
          <h3>Laid-Back Jazz</h3>
          <p>Plus 4 other channels of hand-selected, artfully curated jazz.</p>
        </section>

        <section className="upgrade-premium-section">
          <h2>Upgrade to Premium or Premium Plus</h2>
          <p className="body">
            Upgrade your Jazz Groove experience with song skips, spectacular
            audio, and unlimited listening with a tax-deductible donation.
          </p>

          <div className="upgrade-cards">
            <div className="upgrade-card">
              <h3>Premium</h3>
              <p className="price">
                <span className="dollar-sign">$</span>5
              </p>
              <p className="price-sub">per month</p>
              <p className="small">no commitments</p>
              <Link to="/checkout/payment?a=500&ar=1&m=1&s=1&source=sonos">
                <button className="sign-up">
                  Sign Up
                </button>
              </Link>
              <details>
                <summary className="see-benefits">See benefits</summary>
                <ul className="benefits">
                  <li>Song Skips</li>
                  <li>Audiophile Fidelity</li>
                  <li>Silence Premium Promotional Announcements</li>
                  <li>Unlimited Listening</li>
                  <li>Tax-deductible donation</li>
                </ul>
              </details>
            </div>

            <div className="upgrade-card">
              <h3>Premium Plus</h3>
              <p className="price">
                <span className="dollar-sign">$</span>10
              </p>
              <p className="price-sub">per month</p>
              <p className="small">no commitments</p>
              <Link to="/checkout/payment?a=1000&ar=1&m=1&s=1&source=sonos">
                <button className="sign-up">
                  Sign Up
                </button>
              </Link>
              <details>
                <summary className="see-benefits">See benefits</summary>
                <ul className="benefits">
                  <li>Silence All Fund Drive Announcements</li>
                  <li>Song Skips</li>
                  <li>Audiophile Fidelity</li>
                  <li>Unlimited Listening</li>
                  <li>Tax-deductible donation</li>
                </ul>
              </details>
            </div>
          </div>
        </section>

        <section className="final-pitch">
          <h2>Begin listening now, free</h2>
          <p className="body">
            Support non-profit, non-commercial jazz to instantly access to 5
            artfully curated channels
          </p>
          <button className="free-account-button" onClick={()=>loginWithRedirect({ screen_hint: "signup", redirect_uri: process.env.REACT_APP_DOMAIN + "/link/sonos/complete" })}>
            Create free account
          </button>
        </section>

        <footer className="sonos-footer">
          <p className="small">Foundation To Advance Jazz</p>
          <p className="small">501(c)(3) nonprofit organization</p>
        </footer>
      </main>
    </div>
  );
};

export default SonosLander;
