/* eslint-disable */
import React, { useState, Fragment, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { isExpiring } from "Utils/Account/Plan";
import swal, { closeModal } from "../Swal";
import Axios from "axios";
import moment from "moment";
import { getCleanPlan } from "../Account/Subscription";
import { useAuth0 } from "../../../react-auth0-spa";
import ReactGA from "react-ga";
import { getQueryStringValue } from "Pages/Checkout";
import History from "Utils/Routing/History";
import { UNLIMITED_LISTENING_PLAN } from "Utils/Constants";
import ContentfulClient from "Utils/ContentfulClient";

let reloadInterval;

const Nav = (props) => {
  const [expiring, setExpiring] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const [buttonText, setButtonText] = useState('');

  useEffect(() => {
    const reloadExpiring = () => {
      isExpiring()
        .then((e) => {
          if (getQueryStringValue("action") === "renew") {
            openExpiringModal(() => setExpiring(false));
          }
          setExpiring(e);
        })
        .catch(() => setExpiring(false));
    };
    reloadExpiring();
    clearInterval(reloadInterval);
    reloadInterval = setInterval(() => reloadExpiring(), 3600000);
  }, [isAuthenticated]);

  useEffect(()=>{
    if (isAuthenticated && user) {
      window.identify(user.email, {
        sub: user.sub,
        plan: user.plan,
      },
      Upscope('updateConnection', {
        // Set the user ID below. If you don't have one, set to undefined.
        uniqueId: user.plan,
      
        // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
        identities: [user.family_name, user.given_name, user.email]
      }))
    }
  }, [isAuthenticated, user]);

  useEffect(()=> {
    ContentfulClient.getEntry("5qJ7TyTaT6jt5Ecdi6YmuH")
      .then((entry) => {
        setButtonText(entry.fields.buttonText);
      })
      .catch(e => console.error(e))
  }, []);

  //Event listener checks screen width
  //Necessary to dynamically add elements to different menus
  //depending on screen width
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
   
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [window, setWidth]);
  
  return [
    <div className="mobile" key={0}>
      <Link to="/" className={"logo"}>
        <img
          src="https://assets.jazzgroove.org/updated-logo.svg"
          alt="jazz groove logo"
        />
      </Link>

      <button
        className="user"
        onClick={()=>{
          if (isAuthenticated)
            History.push("/account");
          else loginWithRedirect();
        }}
        aria-label="Your Account"
      >
        <i className="fas fa-user" />
      </button>
      <div className="expiring">
        {expiring && (
          <button
            key={1}
            className="standard"
            onClick={() => {
              ReactGA.event({
                category: "button",
                action: "expiring",
                days: expiring.days,
              });
              openExpiringModal(() => setExpiring(false));
            }}
          >
            <i className="fas fa-exclamation-circle"></i> EXPIRES IN
            {` ${expiring.days} `}
            DAYS
          </button>
        )}
      </div>
      <button
        className="donate"
        onClick={() => window.open("/checkout", "_blank")}
      >
        Donate
      </button>
      <button 
        className="menu-button"
        onClick={() => props.setShow(!props.show)}
      >
        <i className="fa fa-bars" />
      </button>
      {
        props.show && 
        hamburgerMenuBuilder(props, width, buttonText)
      }
    </div>,
    <nav
      className={props.show ? "show header-nav" : "header-nav"}
      aria-label="header-nav"
      style={
        props.home
          ? { background: `rgba(255,255,255, ${props.background})` }
          : { background: "white" }
      }
      key={1}
    >
      <ul className={"navigation"}>
        <li className={props.home ? "active" : null}>
          <Link to="/" aria-label="Home">
            <i title="home" className="fas fa-home" />
            <span className="hidden">Home</span>
          </Link>
        </li>
        <li
          className={
            props.location.pathname === "/devices" ? "active" : null
          }
        >
          <Link to="/devices">{buttonText}</Link>
        </li>
        <li
          className={props.location.pathname === "/premium" ? "active" : null}
        >
          <Link to="/premium">Get Premium</Link>
        </li>
      </ul>
      <ul className={"action-buttons"}>
        <li className="logged-in-status">
          {isAuthenticated ? (
            [
              <Link key={0} to="/account" className="account">
                Account
              </Link>,
              <div className="expiring" key={1}>
                {expiring && (
                  <button
                    className="standard"
                    onClick={() => openExpiringModal(() => setExpiring(false))}
                  >
                    <i className="fas fa-exclamation-circle"></i> EXPIRES IN
                    {` ${expiring.days} `}
                    DAYS
                  </button>
                )}
              </div>,
            ]
          ) : (
            <Fragment>
              <button
                key={0}
                onClick={loginWithRedirect}
                className="login"
              >
                Sign In
              </button>
            </Fragment>
          )}
        </li>
        <li>
          <a href="/checkout" target="_blank" className="donate">
            Donate
          </a>
        </li>
        <li>
          <button 
            className="menu-button"
            onClick={() => props.setShow(!props.show)}s
          >
            <i className="fa fa-bars" />
          </button>
        </li>
        <li>
          {
            props.show && 
            hamburgerMenuBuilder(props, width)
          }
        </li>
      </ul>
      
    </nav>,
  ];
};

async function openExpiringModal(removeExpiringBadge) {
  const expiring = await isExpiring();
  swal({
    title: null,
    buttons: false,
    showCloseButton: true,
    className: "custom-swal-modal",
    content: (
      <ExpiringModal
        {...expiring}
        removeExpiringBadge={removeExpiringBadge}
      />
    ),
  });
}

const ExpiringModal = (props) => {
  return (
    <div className="expiring-modal">
      <p className="expiring">
        Your {getCleanPlan(props.plan)} plan is expiring{" "}
        <span className="days">in {props.days} days</span>.
      </p>
      <ExpiringButtons
        subscriptionId={props.subscriptionId}
        removeExpiringBadge={props.removeExpiringBadge}
        ttl={props.ttl}
        plan={props.plan}
      />
    </div>
  );
};

const ExpiringButtons = (props) => {
  switch (props.plan) {
    case UNLIMITED_LISTENING_PLAN:
      return (
        <Fragment>
          <div className="primary">
            <button
              className="standard"
              onClick={() => {
                window.open("/checkout?a=1000&ar=1&m=1", "_blank");
              }}
            >
              Renew at $10/month
            </button>
            <button
              className="standard"
              onClick={() => window.open("/checkout?a=500&ar=1&m=1", "_blank")}
            >
              Renew at $5/month
            </button>
          </div>
          <button
            className="other-options"
            onClick={() => {
              closeModal();
              History.push("/premium");
            }}
          >
            See other options
          </button>
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <button
            className="standard"
            onClick={() => {
              Axios.post(
                process.env.REACT_APP_API_ENDPOINT + "/donate/reactivate",
                { id: props.subscriptionId }
              ).then(() => {
                props.removeExpiringBadge();
                swal("Successfully updated plan.");
              });
            }}
          >
            Renew Now
          </button>
          <p className="details">
            *Will renew on {moment.unix(props.ttl).format("MMMM Do, YYYY")}
          </p>
          <button
            className="other-options"
            onClick={() => {
              closeModal();
              window.open("/checkout", "_blank");
            }}
          >
            See other options
          </button>
        </Fragment>
      );
  }
};

/**
 * Builds nav menu for hamburger button
 * If width of screen is mobile <=940px
 * Hamburger button will encompass entire nav menu options
 * Else
 * Hamburger button will contain About, Blog, and Jazz Tonight
 * @param {Object} props 
 * @param {Integer} width of screen in pixels 
 * @returns ul of menu items for hamburger menu
 */
function hamburgerMenuBuilder(props, width, buttonText){

  if(width <= 940){
    return(
      <ul className="hamburger-menu-mobile">
          <li className={props.home ? "active" : null}>
          <Link to="/" aria-label="Home">
            <i title="home" className="fas fa-home" />
            <span className="hidden">Home</span>
          </Link>
        </li>
        <li
          className={
            props.location.pathname === "/devices" ? "active" : null
          }
        >
          <Link to="/devices">{buttonText}</Link>
        </li>
        <li
          className={props.location.pathname === "/premium" ? "active" : null}
        >
          <Link to="/premium">Get Premium</Link>
        </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/jazz-tonight">Jazz Tonight</Link>
          </li>
        </ul>
    );
  }else{
    return(
    
      <ul className="hamburger-menu">
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/jazz-tonight">Jazz Tonight</Link>
        </li>
      </ul>
  
    )
  }
}


export default withRouter(Nav);