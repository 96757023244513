import React, { useState } from "react";
import Spinner from "../Spinner";

const Coupon = (props) => {
  const [loading, setLoading] = useState(false);
  if (props.coupon.formatted)
    return (
      <div className="coupon successful">
        <p className="body">
          <i className="fas fa-check-circle"></i>{" "}
          <strong>{props.coupon.value}</strong> successfully applied! You've
          received <strong>{props.coupon.formatted}</strong> off.
        </p>
        <button type="button" className="remove-coupon" onClick={props.remove}>
          <i className="fas fa-times"></i> Remove Coupon
        </button>
        {props.coupon.message && <p className="tiny warning">
          {props.coupon.message}
        </p>}
      </div>
    );
  return (
    <div className="coupon">
      <label htmlFor="coupon">Have a coupon code?</label>
      <div className="flex">
        <input
          name="coupon"
          id="coupon"
          defaultValue={props.coupon.value}
          onChange={(e) => props.updateCoupon(e.target.value)}
          onKeyPress={async (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setLoading(true);
              await props.applyCoupon();
              setLoading(false);
            }
          }}
        />
        <button
          type="button"
          className="standard"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await props.applyCoupon();
            setLoading(false);
          }}
        >
          {loading ? <Spinner /> : "Apply"}
        </button>
      </div>
      {props.coupon.error && (
        <p className="tiny error">
          This code doesn't match any active promotions. Please try again.
        </p>
      )}
    </div>
  );
};

export default Coupon;
