import React, { useState } from "react";
import { closeModal } from "../Swal";
import premiumAlert from "Utils/PlayerAlert";
import { isPremium } from "Utils/Account/Plan";
import {
  HIGH_QUALITY,
  MID_QUALITY,
  LOW_QUALITY,
} from "Utils/Constants";
import History from "Utils/Routing/History";

const GearMenu = (props) => {
  const [currentQuality, setCurrentQuality] = useState(
    props.qualityPreference
  );
  return (
    <div className={"gear-modal"}>
      <form className="quality-options-container">
        <h2>Quality</h2>
        <div
          className={
            currentQuality === LOW_QUALITY || !currentQuality
              ? "input-radio-button-container input-radio-button-selected"
              : "input-radio-button-container"
          }
          onClick={() => {
            if (currentQuality !== LOW_QUALITY) {
              let returnValue = Object.assign({}, props.returnValue);
              returnValue.quality = LOW_QUALITY;
              props.setReturnValue(returnValue);
              setCurrentQuality(LOW_QUALITY);
            }
          }}
        >
          <span className="input-radio-button-fake" />
          <input
            type="radio"
            name="audio-quality"
            id="128k"
            value={LOW_QUALITY}
            selected={currentQuality === LOW_QUALITY}
          />
          <label htmlFor="128k">128k AAC (good)</label>
        </div>

        {/* AVAILABLE FOR PREMIUM MEMBERS ONLY */}
        <div
          className={
            currentQuality === MID_QUALITY
              ? "input-radio-button-container input-radio-button-selected"
              : "input-radio-button-container"
          }
          onClick={() => {
            if (props.isAuthenticated && isPremium(props.user.plan)) {
              if (currentQuality !== MID_QUALITY) {
                let returnValue = Object.assign({}, props.returnValue);
                returnValue.quality = MID_QUALITY;
                props.setReturnValue(returnValue);
                setCurrentQuality(MID_QUALITY);
              }
            } else {
              premiumAlert({
                loginWithRedirect: props.loginWithRedirect,
                popup: props.popup,
                loggedIn: props.isAuthenticated,
              });
            }
          }}
        >
          <input
            type="radio"
            name="audio-quality"
            id="256k"
            value={MID_QUALITY}
            selected={currentQuality === MID_QUALITY}
            disabled={!props.isAuthenticated || !isPremium(props.user.plan)}
          />
          <div className="input-radio-button-fake" />
          <label htmlFor="256k">256k AAC (great)</label>
        </div>

        {/* AVAILABLE FOR PREMIUM MEMBERS ONLY */}
        <div
          className={
            currentQuality === HIGH_QUALITY
              ? "input-radio-button-container input-radio-button-selected"
              : "input-radio-button-container"
          }
          onClick={() => {
            if (props.isAuthenticated && isPremium(props.user.plan)) {
              if (currentQuality !== HIGH_QUALITY) {
                let returnValue = Object.assign({}, props.returnValue);
                returnValue.quality = HIGH_QUALITY;
                props.setReturnValue(returnValue);
                setCurrentQuality(HIGH_QUALITY);
              }
            } else {
              premiumAlert({
                loginWithRedirect: props.loginWithRedirect,
                popup: props.popup,
                loggedIn: props.isAuthenticated,
              });
            }
          }}
        >
          <input
            type="radio"
            name="audio-quality"
            id="flac"
            value={HIGH_QUALITY}
            selected={currentQuality === HIGH_QUALITY}
            disabled={!props.isAuthenticated || !isPremium(props.user.plan)}
          />
          <div className="input-radio-button-fake" />
          <label htmlFor="flac">FLAC (best)</label>
        </div>

        {/* SHOW WHEN NOT A PREMIUM MEMBER */}
        {(!props.isAuthenticated || !isPremium(props.user.plan)) && (
          <p>
            <a href="/premium">Upgrade to Premium</a> for higher quality audio.
          </p>
        )}
      </form>

      <div className="external-player-option-container">
        <h2>External Player</h2>
        <div className="checkbox">
          <input
            type="checkbox"
            name="external-player-checkbox"
            id="use-external-player"
            defaultChecked={props.external}
            onChange={(e) => {
              let returnValue = Object.assign({}, props.returnValue);
              returnValue.external = e.target.checked;
              props.setReturnValue(returnValue);
            }}
          />
          <label htmlFor="use-external-player">
            Use external player (iTunes, VLC, Winamp, etc.){" "}
            <span>Mix 1 and Mix 2 only</span>
          </label>
        </div>
      </div>

      <div className="listening-options-container">
        <h2>More Listening Options</h2>
        <p>
          See our{" "}
          <button
            className="listen"
            onClick={() => {
              if (props.popup) {
                try {
                  window.opener.parent.location.href = "/listen";
                } catch (e) {
                  window.open("/listen");
                }
              } else History.push("/listen");
              closeModal();
            }}
          >
            FAQ
          </button>{" "}
          for more ways to listen.
        </p>
      </div>
      <p className="tiny">
        Quality preferences are applied on the next track and may be downgraded
        during periods of slow connectivity.
      </p>
    </div>
  );
};

export default GearMenu;
