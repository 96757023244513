export const UNLIMITED_LISTENING_PLAN = "unlimited-listening";
export const PREMIUM_PLAN = "premium";
export const PREMIUM_PLUS_PLAN = "premium-plus";

export const AUTH0_USER_METADATA_KEY = "https://jazzgroove.org/user_metadata";
export const AUTH0_MANAGEMENT_AUDIENCE = "https://jazzgroove.us.auth0.com/api/v2/"

export const LOW_QUALITY = "audio-mp4-aac-128k";
export const MID_QUALITY = "audio-mp4-aac-256k";
export const HIGH_QUALITY = "audio-mp4-flac";

export const USER_ENDPOINT = "/user";
export const DONATION_HISTORY_ENDPOINT = "/donate/history";
export const CHECKOUT_ENDPOINT = "/checkout";
export const GENERATE_TOKEN = "/stream/generate-token"