/**
 * Returns the os of the mobile parameter that is included
 * if the os doesnt exist or is invalid it returns undefined
 * @returns String || undefined
 */
export function isRedirectedFromMobileApp(){
    let curOs = new URLSearchParams(window.location.search).get('os');

    if(curOs && (curOs === 'ios' || curOs === 'android')){
        return curOs;
    }

    return undefined;
}