import React, { useState, Suspense } from "react";
import "react-image-lightbox/style.css";
import retry from "Utils/ImportRetry";

const Lightbox = React.lazy(() => retry(() => import("react-image-lightbox")));

export const ImageWithLightbox = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <img
        {...props}
        className={props.className ? props.className + " zoom" : "zoom"}
        alt="album art"
        onClick={() => (props.onClick ? props.onClick() : setOpen(true))}
      />
      {open && (
        <Suspense fallback={<div></div>}>
          <Lightbox mainSrc={props.src} onCloseRequest={() => setOpen(false)} />
        </Suspense>
      )}
    </div>
  );
};

export const BackgroundImageWithLightbox = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      style={props.src ? { backgroundImage: `url(${props.src})` } : {}}
      className={
        props.className ? props.className + (props.src && " zoom") : "zoom"
      }
      alt="album art"
      onClick={() =>
        props.onClick ? props.onClick() : props.src && setOpen(true)
      }
    >
      {open && (
        <Suspense fallback={<div></div>}>
          <Lightbox mainSrc={props.src} onCloseRequest={() => setOpen(false)} />
        </Suspense>
      )}
    </div>
  );
};
