import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ChannelSelector extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.getPosition = this.getPosition.bind(this);
    this.reloadPosition = this.reloadPosition.bind(this);
  }

  state = {
    showChannels: false,
    premium: false,
    disabled: false,
    position: {},
  };

  componentDidMount() {
    window.addEventListener("resize", this.reloadPosition);
    window.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reloadPosition);
    window.removeEventListener("click", this.handleClick);
  }

  reloadPosition() {
    var x = this.getPosition();
    this.setState({ positon: x });
  }

  handleClick = () => {
    if (this.state.showChannels) {
      this.setState({
        showChannels: false,
      });
    }
  };

  getPosition() {
    if (this.props.below) {
      return {
        display: "block",
        position: "absolute",
        top: this.myRef.current
          ? this.myRef.current.offsetTop + this.myRef.current.offsetHeight + 10
          : null,
        left: this.myRef.current
          ? this.myRef.current.offsetLeft -
            (225 - this.myRef.current.offsetWidth)
          : null,
      };
    }
    return {
      display: "block",
      position: "absolute",
      top: this.myRef.current ? this.myRef.current.offsetTop : null,
      left: this.myRef.current
        ? this.myRef.current.offsetLeft + this.myRef.current.offsetWidth + 10
        : null,
    };
  }

  render() {
    return (
      <div>
        <button
          aria-label="Switch channel"
          className="channel-selector"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ showChannels: !this.state.showChannels });
          }}
          ref={this.myRef}
        >
          <div>
            {this.props.showChannel ? (
              [
                <p key={0}>Select Channel</p>,
                <p key={1}>
                  {this.props.channels[this.props.activeChannel].name}
                </p>,
              ]
            ) : (
              <p>Change Channel</p>
            )}
          </div>

          <i className="fa fa-bars" />
        </button>

        <div
          tabIndex="-1"
          className={"channels"}
          style={
            this.state.showChannels ? this.state.position : { display: "none" }
          }
        >
          <form>
            <h3>Switch Channel To:</h3>

            {this.props.channels.map((channel, index) => {
              if (this.props.external && index > 1) {
                if (index === this.props.activeChannel)
                  this.props.setActiveChannel(0);
                return null;
              }
              return (
                <Channel
                  key={index}
                  disabled={this.state.disabled}
                  selected={index === this.props.activeChannel}
                  channel={channel.name}
                  description={channel.description}
                  setActiveChannel={(i) => {
                    this.props.setActiveChannel(i);
                    this.setState({ disabled: true });
                    setTimeout(() => this.setState({ disabled: false }), 1000);
                  }}
                  index={index}
                  hideSelector={() => this.setState({ showChannels: false })}
                  showChannel={this.props.showChannel}
                />
              );
            })}
            <button
              type="button"
              className="close-channel-selector"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ showChannels: !this.state.showChannels });
              }}
            >
              <i className="fas fa-times" /> Close
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default ChannelSelector;

const Channel = withRouter((props) => {
  return (
    <label
      className="channel"
      htmlFor={
        props.showChannel ? `${props.channel}-player` : `${props.channel}-home`
      }
      onClick={(e) => {
        if (props.disabled) {
          return;
        }
        e.stopPropagation();
        props.setActiveChannel(props.index);
        props.hideSelector();
      }}
    >
      <input
        className="with-gap"
        type="radio"
        name={
          props.showChannel
            ? `${props.channel}-player`
            : `${props.channel}-home`
        }
        id={
          props.showChannel
            ? `${props.channel}-player`
            : `${props.channel}-home`
        }
        value={0}
        checked={props.selected}
        onChange={() => {}}
      />
      <span>
        <p className="channel-name">{` ${props.channel}`}</p>
        {/* FEATURE: STATION CHRISTMAS LIGHTS */}
        {/* {props.channel === "The Christmas Jazz Station" && (
          <ul class="lightrope">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        )} */}
        <p className="description">{props.description}</p>
      </span>
    </label>
  );
});
