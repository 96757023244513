import React, { useState } from "react";
import Gift from "./Gift";

function compareGifts(type, a, b) {
  if (a.first) return -1;
  else if (b.first) return 1;
  if (
    !(
      a.meta_data &&
      a.meta_data.pricing &&
      a.meta_data.pricing[type] &&
      a.meta_data.pricing[type].minimum
    )
  )
    return -1;
  if (
    !(
      b.meta_data &&
      b.meta_data.pricing &&
      b.meta_data.pricing[type] &&
      b.meta_data.pricing[type].minimum
    )
  )
    return 1;
  if (a.meta_data.pricing[type].minimum > b.meta_data.pricing[type].minimum)
    return 1;
  else if (
    a.meta_data.pricing[type].minimum < b.meta_data.pricing[type].minimum
  )
    return -1;

  if (a.name > b.name) return 1;
  else if (a.name < b.name) return -1;
  return 0;
}
const SelectGift = (props) => {
  const [showGifts, setShowGifts] = useState(false);
  return (
    <div className="gifts" id="gifts">
      <hr className="divider" />
      {props.gifts
        .sort((a, b) =>
          compareGifts(props.monthly ? "monthly" : "oneTime", a, b)
        )
        .filter(
          (gift) =>
            !props.monthly ||
            (gift.meta_data &&
              gift.meta_data.pricing &&
              gift.meta_data.pricing.monthly) ||
            gift.first
        )
        .map((gift, index) => {
          if (index === 0 && props.isGift) {
            return (
              <Gift
                key={index}
                {...gift}
                monthly={props.monthly}
                selected={props.gift.id === gift.id}
                name={"No physical gift needed for my gift recipient"}
                selectGift={(size) =>
                  props.selectGift({
                    ...gift,
                    size: size,
                  })
                }
              />
            );
          }
          if (index <= 1 || showGifts) {
            return (
              <Gift
                key={index}
                {...gift}
                monthly={props.monthly}
                selected={props.gift.id === gift.id}
                selectGift={(size) =>
                  props.selectGift({
                    ...gift,
                    size: size,
                  })
                }
              />
            );
          }
          return null;
        })}
      <button
        className="show-gifts"
        onClick={() => {
          setShowGifts((s) => !s);
        }}
      >
        Show {showGifts ? "Fewer" : "More"} Gifts
      </button>
      {props.monthly && showGifts && (
        <button
          className="show-gifts"
          onClick={() => {
            props.setToOneTime();
          }}
        >
          Larger Gift Selection With One Time $100 Donation
        </button>
      )}

      {showGifts && (
        <button className="button-primary" onClick={props.sendToPayment}>
          Continue to Payment
        </button>
      )}
    </div>
  );
};

export default SelectGift;
