import Axios from "axios";
import { getEndDate, daysUntil } from "Pages/Components/Account/Subscription";
import { getTokenSilently, getUser } from "react-auth0-spa";
import {
  DONATION_HISTORY_ENDPOINT,
  PREMIUM_PLAN,
  PREMIUM_PLUS_PLAN,
  UNLIMITED_LISTENING_PLAN,
  USER_ENDPOINT,
  AUTH0_MANAGEMENT_AUDIENCE
} from "Utils/Constants";
import moment from "moment";

export async function refreshAttributes() {
  try {
    const accessToken = await getTokenSilently();
    const attributes = (
      await Axios.get(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    ).data;
    return attributes;
  } catch (e) {
    return null;
  }
}

export async function getPlanDetails(email, reload) {
  return new Promise(async (resolve, reject) => {
    if (email) {
      try {
        if (reload) {
          const response = await Axios.post(
            process.env.REACT_APP_API_ENDPOINT + USER_ENDPOINT,
            {
              email: email,
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
              },
            }
          );
          resolve(response.data);
          return;
        } else {
          const response = await Axios.get(
            process.env.REACT_APP_API_ENDPOINT +
              USER_ENDPOINT +
              "?email=" +
              encodeURIComponent(email),
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
              },
            }
          );
          resolve(response.data);
          return;
        }
      } catch (e) {}
    }
    resolve("");
  });
}

export function isPremiumPlus(plan) {
  return typeof plan === "string" && plan.includes(PREMIUM_PLUS_PLAN);
}

export function isPremium(plan) {
  return typeof plan === "string" && plan.includes(PREMIUM_PLAN);
}

export function isPremiumOrUnlimited(plan) {
  return typeof plan === "string" && (plan.includes(UNLIMITED_LISTENING_PLAN) || plan.includes(PREMIUM_PLAN));
}

export function isUnlimited(plan) {
  return typeof plan === "string" && plan.includes(UNLIMITED_LISTENING_PLAN);
}

export function isExpiring() {
  return getActiveDonationHistory()
    .then((history) => {
      if (
        history.length === 1 &&
        history[0].subscription.status === "non_renewing"
      ) {
        const days = daysUntil(
          new Date(
            getEndDate(
              history[0].subscription.status,
              history[0].subscription.remaining_billing_cycles,
              history[0].subscription.billing_period_unit,
              history[0].subscription.current_term_end,
              history[0].subscription.plan_id
            ).time
          )
        );
        if (days <= 14)
          return {
            days: Math.max(days, 0),
            ttl: history[0].subscription.current_term_end,
            subscriptionId: history[0].subscription.id
          };
      } else if (history.length === 0) {
        return getUser()
          .then((user) => {
            if (user.plan && !user.subscriptionId) {
              const days = moment.unix(user.ttl).diff(moment(), "days");
              if (days <= 14) {
                return {
                  days: Math.max(days, 0),
                };
              }
            }
            return false;
          })
          .catch(() => false);
      }
    })
    .catch(() => false);
}

export async function getActiveDonationHistory() {
  const accessToken = await getTokenSilently({
    audience: AUTH0_MANAGEMENT_AUDIENCE,
    scope: "update:current_user_metadata",
  });
  const response = await Axios.post(
    process.env.REACT_APP_API_ENDPOINT + DONATION_HISTORY_ENDPOINT,
    {
      accessToken: accessToken,
    },
    {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    }
  );
  if (response.data.list)
    return response.data.list.filter(
      (x) => x.subscription && x.subscription.status !== "cancelled"
    );
  return [];
}
