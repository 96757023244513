import Axios from "axios";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { matchPath } from "react-router";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as player from "./AudioPlayer";
import { context } from "./Context";

import swal from "./Pages/Components/Swal";
import "./Styles/app.scss";
import ChannelRedirect from "Utils/Routing/ChannelRedirect";
import Routes from "Utils/Routing/Routes";
import History from "Utils/Routing/History";
import ContentfulClient from "Utils/ContentfulClient";
import { auth0Initialized } from "react-auth0-spa";

let maxListenTime = 0;
let maxListenText =
  "Weekly Free Listening Cap Exceeded. Already Donated? Login to your account. Or, make a donation.";

export { maxListenTime, maxListenText };

let tracker;
let listenTime = 0;
const channels = [
  {
    name: "The Jazz Groove Mix #1",
    description: "Laid-Back Jazz.",
    playlistId: "bab24f05-73f8-4088-9373-bf118f07bdec",
    channelId: "jazzgroove-mix1",
    queryCode: "mix1",
  },
  {
    name: "The Jazz Groove Mix #2",
    description: "Laid-Back Jazz.",
    playlistId: "bf099ca1-68e5-4611-aed2-f3914eed074c",
    channelId: "jazzgroove-mix2",
    queryCode: "mix2",
  },
  {
    name: "Dreams",
    description: "The Softest Laid Back Jazz For Sleep",
    playlistId: "7db4781b-63eb-479c-b018-a7498423c680",
    channelId: "jazzgroove-dreams",
    queryCode: "dreams",
  },
  {
    name: "Gems",
    description: "Vocal Standards",
    playlistId: "5b6248df-d419-4de2-b32a-efd3e1d3bfb3",
    channelId: "jazzgroove-gems",
    queryCode: "gems",
  },
  {
    name: "Smooth Jazz",
    description: "The Smoothest Place On Earth",
    playlistId: "eb33de06-5c1d-4b60-b2d3-a6f7b472f2cd",
    channelId: "jazzgroove-smooth",
    queryCode: "smoothjazz.blue",
  },
];
class App extends Component {
  constructor() {
    super();
    const channel = new URLSearchParams(window.location.search).get("channel");

    let codes = channels.map(function (e) {
      return e.queryCode;
    });
    let channelIndex;
    if (channel) {
      channelIndex = codes.indexOf(channel);
    } else {
      let c = window.location.pathname.slice(1);
      if (c && c === "christmas") {
        c = "gems"
      }
      channelIndex = codes.indexOf(c);
    }
    this.state = {
      songData: {
        aritst: "",
        title: "",
        src: "",
      },
      channels: channels,
      activeChannel: channelIndex >= 0 ? channelIndex : 0,
      raised: 53000,
      scroll: true,
      recentlyPlayed: [],
      external: false,
      currentSongTitle: "",
      paused: true,
      notifications: [],
      firstLoad: true,
    };
  }

  checkForUpdate = () => {
    //get latest version
    Axios.get(`/version.txt?timestamp=${new Date().getTime()}`).then(
      (response) => {
        const latestVersion = response.data.split(".");
        const currentVersion = process.env.REACT_APP_CURRENT_VERSION.split(".");

        if (latestVersion[0] !== currentVersion[0] && !toast.isActive("refresh")) {
          toast(
            <div className="content">
              <p className="update">
                A new version of this website, JazzGroove.org is now available.
              </p>
              <p>Click “Refresh” to get the new version.</p>
              <div className="buttons">
                <button
                  className="dismiss"
                  onClick={() => {
                    clearInterval(this.updateInterval);
                    this.updateInterval = setInterval(
                      this.checkForUpdate,
                      3600000
                    );
                  }}
                >
                  Later
                </button>
                <button
                  className="refresh"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh
                </button>
              </div>
            </div>,
            {
              position: toast.POSITION.BOTTOM_LEFT,
              toastId: "refresh",
            }
          );
        }
      }
    );
  };

  componentDidMount() {
    player.setAudioCallbacks({
      statusUpdate: (e) => {
        if (e.type === "pause") {
          this.setState({ paused: true });
          clearInterval(tracker);
        } else {
          this.setState({ paused: false });
          tracker = setInterval(() => {
            listenTime++;
            if (listenTime === 600) {
              ReactGA.event({
                category: "player",
                action: "5min",
                label: this.state.channels[this.state.activeChannel].channelId,
              });
              /* global dataLayer */
              dataLayer.push({
                event: "listen5mins",
              });
            }
            if (listenTime % 60 === 0) {
              player.incrementMinutes();
              ReactGA.event({
                category: "player",
                action: "stillplaying",
                label: this.state.channels[this.state.activeChannel].channelId,
              });
            }
          }, 1000);
        }
      },
      seeked: (e) => {
        if (
          Math.abs(player.audioPlayer.currentTime - player.lastTimes[0]) > 0.1
        ) {
          player.setIngoreTimeUpdates(true);
          clearTimeout(player.ignoreTimeUpdateTimeout);
          player.setIgnoreTimeUpdateTimeout(
            setTimeout(() => {
              // 1s delay in allowing new time updates.  If they seek again, we're going right back to the old timestamp.
              player.setIngoreTimeUpdates(false);
            }, 1000)
          );
          player.audioPlayer.pause();
          player.audioPlayer.currentTime = player.lastTimes[0]; // Seek back to where we're supposed to be
          swal(
            "Our music licenses do not permit interactivity (skimming forward or backward). Press LISTEN or SKIP SONG to continue listening."
          );
        }
      },
      nowPlayingUpdate: (songData) =>
        this.setState({ songData: songData, currentSongTitle: songData.title }),
      recentlyPlayed: (recentlyPlayed) =>
        this.setState({
          recentlyPlayed: recentlyPlayed.reverse(),
        }),
    });
    this.setActiveChannel(this.state.activeChannel);
    if (process.env.REACT_APP_NODE_ENV !== "dev")
      this.updateInterval = setInterval(this.checkForUpdate, 1800000);
  }

  setActiveChannel = (i) => {
    if (!auth0Initialized) {
      setTimeout(()=>this.setActiveChannel(i), 100);
      return;
    }
    if (!player.newPlaylist) {
      this.setState({
        external: true,
      });
      if (i > 1) i = 0;
    } else {
      if (maxListenTime) {
        player.newPlaylist(
          this.state.channels[i].playlistId
            ? this.state.channels[i].playlistId
            : this.state.channels[0].playlistId,
          this.state.channels[i].channelId,
          this.state.external,
          this.state.paused
        );
      } else {
        ContentfulClient.getEntry("780ge8tqGOkn1s6PawcWLT")
          .then((entry) => {
            maxListenTime = entry.fields.limit;
            maxListenText = entry.fields.modalText;
            player.newPlaylist(
              this.state.channels[i].playlistId
                ? this.state.channels[i].playlistId
                : this.state.channels[0].playlistId,
              this.state.channels[i].channelId,
              this.state.external,
              this.state.paused
            );
          })
          .catch(() => (maxListenTime = 90));
      }
    }
    this.setState(
      {
        activeChannel: i,
      },
      () => {
        let recentlyPlayed = localStorage.getItem("recently-played");
        if (recentlyPlayed) {
          recentlyPlayed = JSON.parse(recentlyPlayed);
          this.setState({
            recentlyPlayed: recentlyPlayed[
              this.state.channels[i].channelId
            ].reverse(),
          });
        } else {
          import("./Assets/RecentlyPlayed").then((RecentlyPlayed) => {
            localStorage.setItem(
              "recently-played",
              JSON.stringify(RecentlyPlayed.default)
            );
            this.setState({
              recentlyPlayed: RecentlyPlayed.default[
                this.state.channels[i].channelId
              ].reverse(),
            });
          });
        }
      }
    );
  };

  getRoute() {
    const match = matchPath(window.location.pathname, {
      path: "/:channel(mix1|mix2|gems|dreams|smoothjazz.blue|christmas)",
      exact: true,
      strict: false,
    });
    if (match !== null) {
      let channel = match.params.channel;
      if (channel === "christmas") {
        channel = "gems";
      }
      if (
        channel !==
        this.state.channels[this.state.activeChannel].queryCode
      )
        return <Redirect to="/" />;
      return (
        <Route path={"/:channel(mix1|mix2|gems|dreams|smoothjazz.blue|christmas)"} />
      );
    }
    return null;
  }

  setExternal = (value) => {
    this.setState({ external: value });
  };

  render() {
    return (
      <context.Provider
        value={{
          ...this.state,
          setActiveChannel: this.setActiveChannel,
          setExternal: this.setExternal,
          updateLogin: this.updateLogin,
        }}
      >
        <div className="app">
          <Helmet>
            <title>
              {this.state.currentSongTitle
                ? this.state.currentSongTitle + " - "
                : ""}
              Jazz Groove
            </title>
          </Helmet>
          <ToastContainer autoClose={false} closeButton={false} />
          <Router history={History}>
            <Switch>
              {this.getRoute()}
              <ChannelRedirect
                channel={
                  this.state.channels[this.state.activeChannel].queryCode
                }
              />
            </Switch>
            <Routes />
          </Router>
        </div>
      </context.Provider>
    );
  }
}

export default App;
