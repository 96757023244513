import React, { Component, Suspense } from "react";
import Nav from "./Nav";
import { withRouter } from "react-router-dom";
import { context } from "Context";
import { matchPath } from "react-router";
import ReactGA from "react-ga";
import SliderImages from "Assets/SliderImages";
import { isWebpSupported } from "react-image-webp/dist/utils";
import retry from "Utils/ImportRetry";
import { isRedirectedFromMobileApp } from "Utils/RedirectedFromMobileApp";

const ImageGallery = React.lazy(() =>
  retry(() => import("react-image-gallery"))
);

class Header extends Component {
  state = {
    show: false,
    background: 0,
    home: false,
  };

  componentDidMount() {
    this.isHome();
    window.addEventListener("scroll", this.handleScroll);
    this.unlisten = this.props.history.listen((location, action) => {
      if (location.pathname !== this.props.location.pathname) {
        window.scrollTo(0, 0);
        this.isHome();
        this.setState({
          show: false,
          background: this.state.home ? 0 : this.state.background,
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    });
  }

  componentDidUpdate() {
    this.isHome();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.unlisten();
  }

  handleScroll = () => {
    this.setState({
      background: this.state.home
        ? Math.min((window.scrollY / window.innerHeight) * 6, 1)
        : this.state.background,
    });
  };

  isHome() {
    const match = matchPath(window.location.pathname, {
      path: "/:channel(mix1|mix2|gems|dreams|smoothjazz.blue|sign-up|christmas)",
      exact: true,
      strict: false,
    });
    const homeMatch = matchPath(window.location.pathname, {
      path: "/",
      exact: true,
      strict: false,
    });
    const home = homeMatch !== null || match !== null;
    if (this.state.home !== home) this.setState({ home: home });
  }

  customRender = (item) => {
    return (
      <div
        className="image-gallery-image"
        style={{
          background: `url("${
            item.webP && isWebpSupported() ? item.webP : item.original
          }")`,
        }}
      >
        {item.title && (
          <div className="image-gallery-description">
            <h1 className="image-gallery-heading">{item.name}</h1>
            {item.channelDesc && (
              <h2 className="image-gallery-channel-desc">{item.channelDesc}</h2>
            )}
            {item.channelDesc ? (
              <h3 className="internet-radio">
                Internet Radio
              </h3>
            ) : (
              <h2 className="internet-radio">
                Internet Radio
              </h2>
            )}
          </div>
        )}

        {item.channelDesc ? (
          <h4 className="image-gallery-artist">{item.title}</h4>
        ) : (
          <h3 className="image-gallery-artist">{item.title}</h3>
        )}
        {item.subtitle && (
          <h4 className="image-gallery-artist credit">{item.subtitle}</h4>
        )}
      </div>
    );
  };

  setShow(a) {
    this.setState({ show: a });
  }

  render() {

    let isFromMobileApp = isRedirectedFromMobileApp();

    if(isFromMobileApp){
      return null;
    }


    return (
      <header className={this.state.home ? "" : "height"}>
        <Nav
          show={this.state.show}
          setShow={(a) => this.setShow(a)}
          background={this.state.background}
          home={this.state.home}
        />

        <context.Consumer>
          {(context) => {
            return (
              this.state.home && (
                <Suspense fallback={<div></div>}>
                  <ImageGallery
                    renderItem={this.customRender}
                    items={SliderImages[context.activeChannel]}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showNav={false}
                    showBullets={false}
                    autoPlay={true}
                    slideInterval={8000}
                  />
                </Suspense>
              )
            );
          }}
        </context.Consumer>
      </header>
    );
  }
}

export default withRouter(Header);
