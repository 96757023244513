import React, { useState } from "react";
import Spinner from "../Spinner";
import ReCAPTCHA from "react-google-recaptcha";

import payPalLogo from "../../../Assets/paypal-logo.svg";
import payPalLogomark from "../../../Assets/paypal-logomark.svg";
import creditCardLogos from "../../../Assets/credit-card-logos.png";
import check from "../../../Assets/check.svg";
import CountryInput from "./CountryInput";
import { PlaidLink } from "react-plaid-link";
import * as Display from "Utils/Display";
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import ReactGA from "react-ga";

const Payment = (props) => {
  const [plaidAccounts, setPlaidAccounts] = useState([]);
  const onSuccess = (token, metadata) => {
    ReactGA.event({
      category: "ach",
      action: "success",
    });
    props.setPlaidToken({
      token: token,
      accountId: metadata.accounts.length ? metadata.accounts[0].id : null,
      mask: metadata.accounts.length ? metadata.accounts[0].mask : null,
    });
    setPlaidAccounts(metadata.accounts);
  };
  console.log(props.error);
  return (
    <div>
      <h2 className="secondary-heading">How would you like to donate?</h2>
      <div className="radio-button-group payment-methods-container">
        <label
          className={
            props.paymentMethod === 0 ? "tab-selected" : "tab-not-selected"
          }
        >
          <input
            className={"with-gap"}
            type={"radio"}
            name={"monthly"}
            value={0}
            checked={props.paymentMethod === 0}
            onChange={() => props.setPaymentMethod(0)}
          />
          <span>
            <p className="tab-title">Credit Card</p>
          </span>
          <img
            src={creditCardLogos}
            alt="Mastercard, Visa, American Express, Discover cards accepted"
            className="payment-type-image"
          />
        </label>

        <label
          className={
            props.paymentMethod === 1 ? "tab-selected" : "tab-not-selected"
          }
        >
          <input
            className={"with-gap"}
            type={"radio"}
            name={"onetime"}
            value={1}
            checked={props.paymentMethod === 1}
            onChange={() => props.setPaymentMethod(1)}
          />
          <span>
            <p className="tab-title">PayPal</p>
          </span>
          <img
            src={payPalLogomark}
            alt="Mastercard, Visa, American Express, Discover cards accepted"
            className="payment-type-image"
          />
        </label>
        {!props.isGift && (
          <label
            className={
              props.paymentMethod === 2 ? "tab-selected" : "tab-not-selected"
            }
          >
            <input
              className={"with-gap"}
              type={"radio"}
              name={"gift"}
              value={2}
              checked={props.paymentMethod === 2}
              onChange={() => {
                props.setAdditional(0);
                props.setPaymentMethod(2);
              }}
            />
            <span>
              <p className="tab-title">Checking Account</p>
            </span>
            <img
              src={check}
              alt="Mastercard, Visa, American Express, and Discover cards accepted"
              className="payment-type-image"
            />
          </label>
        )}
      </div>

      {!props.monthly && !props.isGift && props.amount >= 10000 && (
        <div className="auto-renew checkbox">
          <input
            key={props.autoRenew}
            name="autoRenew"
            id="autoRenew"
            type="checkbox"
            checked={props.autoRenew}
            onChange={(e) => props.setAutoRenew(e.target.checked)}
          />
          <label htmlFor="autoRenew">
            Yes, automatically renew my plan in one year.
          </label>
        </div>
      )}
      {props.paymentMethod !== 2 && (
        <div className="add-fees checkbox">
          <input
            name="addFees"
            id="addFees"
            type="checkbox"
            onChange={(e) => {
              props.setAdditional(
                e.target.checked ? parseInt(props.amount * 0.025) : 0
              );
            }}
          />{" "}
          <label htmlFor="addFees">
            Yes, I'll generously add $
            {Display.formatDonation((props.amount * 0.025) / 100)}{" "}
            {props.autoRenew && props.monthly && "each month"} to cover the
            transaction fees so JazzGroove.org can keep 100% of my donation.
          </label>
        </div>
      )}

      <div>
        {props.paymentMethod === 0 && (
          <CreditForm stripe={props.stripe} elements={props.elements} />
        )}

        {props.paymentMethod === 1 && (
          <section className="paypal-container">
            {props.shipping && (
              <CountryInput
                address={props.address}
                setAddress={props.setAddress}
              />
            )}
            <button className="paypal-button" disabled={props.loading}>
              Pay with
              <img src={payPalLogo} alt="PayPal" />
            </button>
            {props.loading && (
              <div className="paypal-loading-message">
                <p>Redirecting you to PayPal...</p>
                <Spinner />
              </div>
            )}
          </section>
        )}

        {props.paymentMethod === 2 && (
          <div className="plaid-container">
            {props.plaidToken && (
              <h2 className="secondary-heading">Choose an account to use:</h2>
            )}
            {props.plaidToken ? (
              plaidAccounts.map((account) => (
                <label>
                  <input
                    className={"with-gap"}
                    type={"radio"}
                    name={"account"}
                    value={2}
                    checked={props.plaidToken.accountId === account.id}
                    onClick={() => {
                      props.setPlaidToken({
                        ...props.plaidToken,
                        id: account.id,
                        mask: account.mask,
                      });
                    }}
                  />
                  <span>{account.name}</span>
                  <span className="masked-number">****{account.mask}</span>
                </label>
              ))
            ) : (
              <span
                onClick={() =>
                  ReactGA.event({
                    category: "ach",
                    action: "open",
                  })
                }
              >
                <PlaidLink
                  clientName="The Jazz Groove"
                  env={
                    process.env.REACT_APP_NODE_ENV === "dev"
                      ? "sandbox"
                      : "production"
                  }
                  product={["auth"]}
                  publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
                  onSuccess={onSuccess}
                  accountSubtypes={{ depository: ["checking", "savings"] }}
                  onExit={(err, metadata) => {
                    if (err)
                      ReactGA.event({
                        category: "ach",
                        action: "exitWithError",
                      });
                    else
                      ReactGA.event({
                        category: "ach",
                        action: "exit",
                      });
                  }}
                >
                  Connect a bank account
                </PlaidLink>
              </span>
            )}
          </div>
        )}

        {props.setCaptcha && props.paymentMethod === 0 && (
          <div className="captcha">
            <hr className="divider" />
            <p>Confirm you are not a robot by checking this box:</p>
            <ReCAPTCHA
              onChange={props.setCaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            />
            <hr className="divider" />
          </div>
        )}
        {props.error && <p className="form-error-message">{props.error}</p>}

        {(props.paymentMethod === 0 ||
          (props.paymentMethod === 2 && props.plaidToken)) && (
          <button className="button-primary" disabled={props.loading}>
            {props.loading ? (
              <Spinner />
            ) : props.shipping ? (
              "Continue to Shipping"
            ) : (
              "Submit Donation"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default Payment;

const CreditForm = (props) => {
  const [cardError, setCardError] = useState("");
  const [expError, setExpError] = useState("");
  const [cvcError, setCvcError] = useState("");
  return (
    <section className="credit-card-container">
      <label>
        Card Number
        <CardNumberElement
          {...createOptions(props.fontSize)}
          onChange={(e) => {
            e.error ? setCardError(e.error.message) : setCardError("");
          }}
        />
      </label>
      {cardError && <p className="form-error-message">{cardError}</p>}
      <div className="multiple-input-row">
        <label>
          Exp. Date
          <CardExpiryElement
            {...createOptions(props.fontSize)}
            onChange={(e) => {
              e.error ? setExpError(e.error.message) : setExpError("");
            }}
          />
        </label>
        <label>
          CVC
          <CardCvcElement
            {...createOptions(props.fontSize)}
            onChange={(e) => {
              e.error ? setCvcError(e.error.message) : setCvcError("");
            }}
          />
        </label>
      </div>
      {expError && <p className="form-error-message">{expError}</p>}
      {cvcError && <p className="form-error-message">{cvcError}</p>}
    </section>
  );
};

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize: "18px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "HK Grotesk",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};
