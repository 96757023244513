import swal from "@sweetalert/with-react";

export default function customSwal() {
  return new Promise((resolve, reject) => {
    swal(arguments[0]).then(resolve);
    if (!arguments[0].hideCloseButton) {
      let closeButton = document.createElement("button");
      closeButton.className = "swal2-close";
      closeButton.onclick = function() {
        swal.close();
      };
      closeButton.textContent = "×";
      let x = setInterval(() => {
        let modal = document.querySelector(".swal-modal");
        if (modal) {
          modal.insertBefore(closeButton, modal.children[0]);
          closeButton.focus();
          clearInterval(x);
        }
      }, 10);
    }
  });
}

export function closeModal() {
  try {
    swal.close();
  } catch (e) {}
}
