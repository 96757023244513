import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Breadcrumbs = (props) => {
  return (
    <ul className="breadcrumbs">
      {props.breadcrumbs.map((item, index) => {
        return (
          <li>
            <Link
              to={item.route}
              onClick={() => {
                props.setBreadcrumbs(props.breadcrumbs.splice(0, index + 1));
              }}>
              {isMobile && item.mobile ? item.mobile : item.text}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs;
