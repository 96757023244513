import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "regenerator-runtime/runtime";
import { Auth0Provider } from "./react-auth0-spa";
import * as Sentry from "@sentry/react";
import History from "Utils/Routing/History";
import { checkBlock } from "AudioPlayer";
require("./Utils/Init");

const onRedirectCallback = (appState) => {
  History.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
  try {
    checkBlock();
  } catch (e) {}
};

function FallbackComponent() {
  return <div>An error has occured</div>;
}

let ErrorBoundary;
if (process.env.REACT_APP_NODE_ENV !== "dev") {
  ErrorBoundary = Sentry.ErrorBoundary;
} else {
  ErrorBoundary = (props) => props.children;
}

ReactDOM.render(
  <ErrorBoundary fallback={FallbackComponent} showDialog>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      initOptions={{ cacheLocation: "localstorage" }}
    >
      <App />
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
