import React, { useState, Fragment } from "react";
import Coupon from "./Coupon";
import * as Display from "Utils/Display";
import { getDonationPlan } from "./SelectAmount";
import { getCleanPlan } from "../Account/Subscription";
import { isPremiumPlus, isPremium } from "Utils/Account/Plan";

const Summary = (props) => {
  const [showSummary, setShowSummary] = useState(false);
  function getTotal() {
    if (props.coupon.applied) {
      if (props.coupon.discountType === "fixed_amount") {
        return Display.formatDonation(
          Math.max(
            props.intl
              ? props.amount + 1500 - props.coupon.discountAmount
              : props.amount - props.coupon.discountAmount,
            0
          ) / 100
        );
      } else {
        return Display.formatDonation(
          Math.max(
            props.intl
              ? props.amount * (1 - props.coupon.discountAmount / 100) + 1500
              : props.amount * (1 - props.coupon.discountAmount / 100),
            0
          ) / 100
        );
      }
    }
    return Display.formatDonation(
      (props.intl ? props.amount + 1500 : props.amount) / 100
    );
  }
  return (
    <aside className="summary">
      <div
        className={
          showSummary
            ? "sidebar-content sidebar-content--is-expanded"
            : "sidebar-content sidebar-content--is-collapsed"
        }
      >
        <h1 className="chosen-plan">
          {getCleanPlan(getDonationPlan(props.monthly, props.amount))}
        </h1>
        <p className="chosen-price">
          A {props.monthly ? "monthly" : "one time"} payment of $
          {Display.formatDonation(props.amount / 100)}
        </p>
        {props.isGift && (
          <p className="gift-text">
            <span>Gift Membership </span>
            {!props.hideTotal && (
              <span>
                for{" "}
                {props.giftRecipient.first_name +
                  " " +
                  props.giftRecipient.last_name}
              </span>
            )}
          </p>
        )}

        <hr className="summary-divider" />

        <div className="chosen-gift">
          {props.gift.meta_data && props.gift.meta_data.images ? (
            <img
              src={props.gift.meta_data.images[0].url}
              alt={props.gift.name}
              className="gift-image"
            />
          ) : (
            <img
              className="gift-placeholder"
              src="https://assets.jazzgroove.org/logo-circle-white1-no-org.png"
              alt="jazz groove logo"
            />
          )}
          <div>
            <p className="gift-title">
              {props.isGift && props.gift.id === ""
                ? "No physical gift needed for my gift recipient"
                : props.gift.name}
            </p>
            {props.gift.size && (
              <p className="size">Size: {props.gift.size.name}</p>
            )}
          </div>
        </div>

        {!props.hideTotal && (
          <Fragment>
            <hr className="summary-divider" />

            <Coupon
              coupon={props.coupon}
              gift={props.gift}
              setCoupon={props.setCoupon}
              updateCoupon={props.updateCoupon}
              applyCoupon={props.applyCoupon}
              remove={() => {
                props.setCoupon({});
                if (props.gift.fallbackGift) {
                  props.setGift(props.gift.fallbackGift);
                }
              }}
            />
            <hr className="summary-divider" />

            <ul className="subtotal-container">
              <li>
                <span>Subtotal:</span>
                <span>${Display.formatDonation(props.amount / 100)}</span>
              </li>
              {props.shipping && (
                <li>
                  <span>Shipping:</span>
                  <span>{props.intl ? "$15" : "FREE"}</span>
                </li>
              )}

              {props.coupon.applied && (
                <li>
                  <span>Discount:</span>
                  <span>{props.coupon.formatted}</span>
                </li>
              )}

              <li className="total">
                <span>Total:</span>
                <span>${getTotal()}</span>
              </li>
            </ul>
          </Fragment>
        )}

        {props.hideTotal && (
          <div className="included-benefits">
            <hr className="summary-divider" />
            <p>Your plan includes:</p>
            <ul>
              {isPremiumPlus(getDonationPlan(props.monthly, props.amount)) && (
                <li>
                  <i className="fas fa-check-circle"></i> Silence All Fund Drive
                  Messages
                </li>
              )}

              {isPremium(getDonationPlan(props.monthly, props.amount)) && (
                <Fragment>
                  <li key="skips">
                    <i className="fas fa-check-circle"></i> Song Skips
                  </li>
                  <li key="fidelity">
                    <i className="fas fa-check-circle"></i> Audiophile Fidelity
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i> Silence Premium
                    Promotional Announcements
                  </li>
                </Fragment>
              )}
              <li>
                <i className="fas fa-check-circle"></i> Unlimited Listening
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Tax Deductible
              </li>

              {isPremiumPlus(getDonationPlan(props.monthly, props.amount)) && (
                <li>
                  <i className="fas fa-check-circle"></i> Surround 5.1 Player
                </li>
              )}

              {!props.monthly &&
                props.amount < 6000 &&
                "(Each increment of $5 donated provides one month of unlimited listening)"}
            </ul>
          </div>
        )}

        {props.hideTotal && (
          <Upsell monthly={props.monthly} amount={props.amount} />
        )}

        <button
          className="summary-close"
          onClick={() => setShowSummary(!showSummary)}
        >
          <span>
            <i class="fas fa-chevron-up"></i> Hide Donation Summary
          </span>
        </button>
      </div>

      <button
        className="summary-toggle"
        onClick={() => setShowSummary(!showSummary)}
      >
        <span className={showSummary ? "summary-open" : "summary-closed"}>
          <i class="fas fa-chevron-down"></i>
          {showSummary ? " Hide " : " Show "} Donation Summary
        </span>
      </button>
    </aside>
  );
};
export default Summary;

const Upsell = (props) => {
  if (
    (props.monthly && props.amount >= 500 && props.amount < 1000) ||
    (!props.monthly && props.amount >= 6000 && props.amount < 10000)
  ) {
    return (
      <div className="upsell">
        <h3>
          <i className="fas fa-crown"></i>
          Increase your donation by $
          {props.monthly && (1000 - props.amount) / 100}
          {!props.monthly && (10000 - props.amount) / 100} to silence
          interruptions from all Fund Drives{!props.monthly && " for one year"}!
        </h3>
      </div>
    );
  } else if (
    (props.monthly && props.amount < 500) ||
    (!props.monthly && props.amount < 6000)
  ) {
    return (
      <div className="upsell to-premium">
        <h3>
          <i className="fas fa-crown"></i>
          Increase donation by ${props.monthly && (500 - props.amount) / 100}
          {!props.monthly && (6000 - props.amount) / 100} to receive Premium
          benefits:
        </h3>
        <ul>
          <li>Song Skips</li>
          <li>Audiophile Fidelity</li>
          <li>Unlimited Listening</li>
          <li>Silence Premium Promotional Announcements</li>
        </ul>
      </div>
    );
  }
  return null;
};
