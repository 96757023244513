export function formatDonation(amount) {
  let delimiter = ",";
  if (isNaN(amount)) {
    return "";
  }
  let split = amount.toString().split(".");
  let n = split[0];
  let d = split[1] ? split[1] : 0;
  let a = [];
  while (n.length > 3) {
    let nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) {
    a.unshift(n);
  }
  amount = a.join(delimiter);
  if (d) {
    amount = parseFloat(amount + "." + d)
      .toFixed(2)
      .toString();
  }
  return amount;
}
