/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import swal from "./Components/Swal";
import Title from "./Components/Title";
import { Link, Redirect } from "react-router-dom";

import image from "../Assets/help-surroundplayer.jpg";
import { useAuth0 } from "react-auth0-spa";
import { getQueryStringValue } from "./Checkout";
import History from "Utils/Routing/History";
import { isRedirectedFromMobileApp } from "Utils/RedirectedFromMobileApp";

const ThankYou = (props) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [hasAccount] = useState(
    (props.location.state && props.location.state.hasOwnProperty("users")) ||
      isAuthenticated
  );
  const [isGift] = useState(
    props.location.state && props.location.state.hasOwnProperty("gift")
  );
  useEffect(() => {
    /*global dataLayer */
    if (!props.location.state) {
      History.push("/checkout");
    } else if (
      props.location.state.subscription.billing_period_unit === "month" &&
      !props.location.state.subscription.setup_fee
    ) {
      dataLayer.push({
        event: "donationrepeat",
        value: props.location.state.subscription.plan_unit_price / 100,
      });
    } else {
      dataLayer.push({
        event: "donationonetime",
        value: props.location.state.subscription.setup_fee
          ? props.location.state.subscription.setup_fee / 100
          : props.location.state.subscription.plan_unit_price / 100,
      });
    }
    // eslint-disable-next-line
  }, []);
  if (!props.location.state) return null;
  if (isGift) {
    return (
      <div className="order-confirmation page">
        <Title value="Thank You" />
        <div className="contained-content">
          <h1 className="title">Thank You</h1>
          <p className="body">
            Your Gift Membership has successfully been emailed to{" "}
            {props.location.state.gift.gift_receiver.first_name} to claim.
          </p>
          <HelpfulTips />
        </div>
      </div>
    );
  }
  return (
    <div className="order-confirmation page">
      <Title value="Thank You" />
      {hasAccount ? (
        <div>
          {getQueryStringValue("redirect") === "sonos" && (
            <h1 className="title">Return to Sonos to login </h1>
          )}
          <HelpfulTips
            first_name={props.location.state.customer.first_name}
            email={props.location.state.customer.email}
          />
        </div>
      ) : (
        <div className="contained-content">
          <section className="step-box">
            <header>
              <h2 style={{margin: 0}}>Final Step:</h2>
              <h2 style={{margin: 0}}><button
                className="standard"
                onClick={() =>
                  loginWithRedirect({
                    screen_hint: "signup",
                    login_hint: props.location.state.customer.email,
                    redirect_uri: `${process.env.REACT_APP_DOMAIN}/tips`,
                  })
                }
              >
                Choose a password &amp; Get Started
              </button></h2>
            </header>
          </section>
        </div>
      )}
    </div>
  );
};

export default ThankYou;

/**
 * Static Page that shows tips and tricks to the user depending on device
 * if URL Parameter os does not exist shows webapp styling help page with website help content
 * if URL Parameter os = ios then shows mobile app styling help page with ios help content
 * if URL Parameter os = android then shows mobile app styling help page with android help content
 * @param {{}} props 
 * @returns React.Component
 */
export const HelpfulTips = (props) => {

  if(props.mobileOperatingSystem || props.mobileOperatingSystem == ''){
    return mobileContentGenerator(props.mobileOperatingSystem);
  }else{
    return webAppContentGenerator(props);
  }
};

/**
 * Holds static HTML for help page content on mobile devices
 * Will dynamically load different screen shots depending on value of passed os
 * @param {String} os string representation of mobile operating system. Valid parameter options include: "ios", "android"
 * @returns React.Component
 */
function mobileContentGenerator(os){
  if (os != 'ios' && os != 'android') {
    return(<Redirect to ="/404"/>);
  }
  return(
    <div className="page thank-you">
      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I LISTEN?</h3>
        <p>
          Tap the “Play” button
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I SIGN IN?</h3>
        <p>
          Tap the “Sign In” button
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>I FORGOT MY PASSWORD. HOW DO I RESET IT?</h3>
        <p>
          Tap the “Sign In” button, then tap “Forgot Password?” Enter your email (if you have more than one, be sure it is the address associated with your donation) and follow the prompts. You should then receive an email to reset your password. 
          Not receiving the password reset email?  In some cases, the spam filtering system on your email client might have misidentified the automated “Reset Your Password” email as being spam. Try checking the “Spam” folder in your email inbox. It’s also possible that your company’s servers might have blocked your email due to security firewalls. In this case, you should ask your IT administrator to allow emails from support@Jazzgroove.org.
          Another reason you may not have received your password reset is if you chose Facebook or Google to sign in. Next step: tap SIGN IN and then “Continue with Facebook,” or “Continue with Google,” and follow the prompts.
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I SKIP SONGS?</h3>
        <p>
          Song Skip Functionality is coming soon to our app and is available right now via our mobile website: https://jazzgroove.org (our website is optimized for the mobile web). If you use the web version, be sure you are signed into your account, and on iOS devices set “Auto-Lock” to “Never” (under “Display & Brightness”)
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I GET RID OF THE 90 MINUTE CAP MESSAGE OR FUND DRIVE MESSAGES?</h3>
        <p>
          If you have an active Jazz Groove plan, tap “Sign In,” and sign in to your account. If you are signed in and still hear the message, find <a href="http://jazzgroove.org/silence?os=ios">solutions</a> here. If you see “Sign In” in the bottom of the app, that means you are not signed in. Tap “Sign In.” If you see “Account” in the bottom of the app, you are signed in.
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I SET AUDIO FIDELITY?</h3>
        <p>
          The Jazz Groove mobile app for iOS and Android provides world class high-resolution audio including four different settings for Wi-Fi &amp; Cellular. To select the settings you prefer:
        </p>
        <br/>
        <ul>
          <li><u>Step 1:</u> Be signed into your account inside our app (Tap “Sign In”). Don’t have an account? Tap “Sign In,” and “Sign Up.”</li>
          <li><u>Step 2:</u> Tap Account</li>
          <li><u>Step 3:</u> Under “Audio,” you’ll see a dropdown menu for both Wi-Fi &amp; Cellular. Tap it, make your selection and click “Done.”</li>
        </ul>
        <br/>
        <p>
          We offer four options:
        </p>
        <ul>
          <li>64k AAC (Standard)</li>
          <li>128K AAC (Good)</li>
          <li>256K AAC (Great)</li>
          <li>FLAC (Best)</li>
        </ul>
        <br/>
        <p>
          FLAC is available on Android devices but presently is not permitted by Apple in our app. We are working to overcome this block and hope to have a solution soon. Note, most humans cannot discern the difference between 256K AAC &amp; FLAC. Our 256K fidelity option is audiophile quality &amp; is simply extraordinary. FLAC is available in iOS when listening using Safari when you’re logged into your account.
        </p>

        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I CHANGE CHANNELS?</h3>
        <p>
          The Jazz Groove provides you with five different jazz radio stations. Tap “Stations,” to view the menu of Jazz Groove radio stations and tap the one you'd like to listen to first.
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>WHERE IS PLAYLIST INFO?</h3>
        <p>
          To view a list of songs you heard, tap “Recent” at the bottom of the app.
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>SLEEP TIMER:</h3>
        <p>
          We will release a sleep timer in our next version of the app, update expected soon.
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>WHERE MAY I LEARN MORE ABOUT THE JAZZ GROOVE?</h3>
        <p>
          Tap “More.”
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I DONATE?</h3>
        <p>
          Tap the “Donate,” button. Our most popular tax deductible (USA) donation level is $100 a year (or $10 a month) delivering you all the benefits of Jazz Groove Premium Plus including Fund Drive Silencing &amp; more! 
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>WRONG PLAN?</h3>
        <p>
          Under “Account,” if you see the wrong plan displayed, to the right of the incorrect plan’s name is a small blue refresh icon (two half circles with arrows). Tap it. After the icon stops spinning, if that fails to update your plan, tap MY PROFILE SETTINGS, scroll down and SIGN OUT. Give the system five minutes to update and then SIGN IN. You should see the correct plan. If you don’t, email <a href="mailto: support@jazzgroove.org">support@jazzgroove.org</a>
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>DROPOUTS?</h3>
        <p>
          Hi-Res Audio Streaming puts more demands on bandwidth than streaming at CD quality. If you’re hearing dropouts, change your Jazz Groove fidelity setting from FLAC to 256 or 128 or 64 or contact your Internet Service Provider to increase your bandwidth capacity or both.
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I SILENCE FUND DRIVES AND PROMOTIONAL MESSAGES?</h3>
        <p>
          You must be signed into your account. Otherwise: <a href="http://jazzgroove.org/silence?os=ios">Solutions here</a>
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I UPDATE CREDIT CARD INFO?</h3>
        <ul>
          <li>Step 1: SIGN IN to your JazzGroove.org account</li>
          <li>Step 2: Tap ACCOUNT</li>
          <li>Step 3: Tap DONATION HISTORY</li>
          <li>Step 4: Tap UPDATE PAYMENT METHOD. Then, enter the email address associated with your donation and follow the prompts.</li>
        </ul>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I CHANGE MY EMAIL ADDRESS?</h3>
        <p>
        Send us an email (<a href="mailto: support@jazzgroove.org">support@jazzgroove.org</a>) indicating the current email address that is associated with your donation(s) - and - the new email address to which you’d like your account changed.
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <div className="helpful-tip-box-mobile">
        <h3>HOW DO I CANCEL MY MONTHLY OR RECURRING PLAN?</h3>
        <p>
          Under Account &gt; Donation History. Locate the plan you’d like to cancel, tap cancel, and confirm.
        </p>
        {/* <img
          src={os === 'ios' ? 'link to ios' : 'link to android'}
          alt=""
        /> */}
      </div>

      <p className="mobile-help-footer">
        If your question isn’t answered above, we’re happy to assist via email, phone or live assistance when available at JazzGroove.org.<br/>
        Email: <a href="mailto: support@jazzgroove.org">support@jazzgroove.org</a><br/>
        Phone: <a href="tel: 833-789-5299">833-789-JAZZ (5299)</a>
      </p>

      <p className="mobile-help-footer">
        The Foundation to Advance Jazz is a 501(c)(3) non-profit. EIN #81-1511864.
      </p>
    </div>
  );
}

/**
 * Holds static HTML for help page content
 * Is only displayed if the url parameter os= does not exist
 * @param {{}} props an object containing the context of the web app
 * @returns React.Component
 */
function webAppContentGenerator(props){
  return (
    <div className="page thank-you">
      {props.first_name && (
        <h1 className="title">Thank you, {props.first_name}</h1>
      )}

      {props.email && (
        <Fragment>
          <p className="body">
            Your tax-deductible donation supports everything you hear on The
            Jazz Groove. We can't do it without you and so appreciate your
            contribution.
          </p>
          <p className="body">
            We emailed your receipt to {props.email}. Be sure to use that email
            address when you SIGN IN to your account here at JazzGroove.org
          </p>

          <h2>Helpful Tips</h2>
        </Fragment>
      )}

      <div className="initial-tips">
        <p>
          Troubleshooting for Fund Drive Silencing:{" "}
          <Link to="/silence">jazzgroove.org/silence</Link>
        </p>
        <p>
          Troubleshooting for Sonos:{" "}
          <Link to="/sonos">jazzgroove.org/sonos</Link>
        </p>
      </div>

      <div className="helpful-tip-box">
        <h3>Login to your account to access all your benefits</h3>
        <p>
          The SIGN IN button is in the top right corner of the screen. If it says
          ACCOUNT, then you're already logged in.
        </p>
        <img
          src="https://assets.jazzgroove.org/helpful-tips/help-login.jpg"
          alt=""
        />
      </div>

      <div className="helpful-tip-box">
        <h3>Forgot your password?</h3>
        <p>Click the SIGN IN button, then FORGOT PASSWORD</p>
        <img
          src="https://assets.jazzgroove.org/helpful-tips/help-forgot-password.jpg"
          alt=""
        />
      </div>

      <div className="helpful-tip-box">
        <h3>Using the music player</h3>
        <p>
          You can change channels, skip songs, and adjust the volume using the
          player (see diagram below), or adjust audio settings in the “Audio
          Settings” tab under “Account”.
        </p>
        <img
          src="https://assets.jazzgroove.org/helpful-tips/help-player.jpg"
          alt=""
        />
      </div>

      <div className="helpful-tip-box">
        <h3>Select audiophile fidelity</h3>
        <p>
          Open the audio settings menu by clicking the gear icon (
          <i className="fa fas-gear"></i>), then choose your audio quality in
          the menu.
        </p>
        <img
          src="https://assets.jazzgroove.org/helpful-tips/help-audiosettings.jpg"
          alt=""
        />
      </div>

      <div className="helpful-tip-box">
        <h3>Silence announcements</h3>
        <p>
          On your ACCOUNT page, select AUDIO SETTINGS, then use the toggles to
          turn announcements on or off. There are two types of announcements you
          now control: 1. Fund Drives/Premium announcements - and - 2. On Air
          Presenter content. <span style={{ fontWeight: 600 }}>Enable</span>{" "}
          silences them. <span style={{ fontWeight: 600 }}>Disable</span>{" "}
          permits them.
        </p>
        <p style={{ fontWeight: 600 }}>
          If you're still having issues with silencing,{" "}
          <Link to="silence">these troubleshooting tips will help</Link>.
        </p>
        <img
          src="https://assets.jazzgroove.org/helpful-tips/help-profile.jpg"
          alt=""
        />
      </div>

      <div className="helpful-tip-box">
        <h3>Finding the Surround 5.1 Player</h3>
        <p>
          On the <Link to="/listen">Ways to Listen</Link> page, scroll down to
          the "Direct Streams URLs" section. Click the{" "}
          <Link to="/surround">5.1 SURROUND AAC 320K HLS</Link> button to access
          the player.
        </p>
        <img src={image} alt="" />
      </div>

      <div className="helpful-tip-box">
        <h3>Final tips</h3>
        <p>
          Troubleshooting for Fund Drive Silencing:{" "}
          <Link to="/silence">jazzgroove.org/silence</Link>
        </p>
        <p>
          Troubleshooting for Sonos:{" "}
          <Link to="/sonos">jazzgroove.org/sonos</Link>
        </p>
        <p>
          For more helpful tips: <Link to="/faqs">jazzgroove.com/faqs</Link>
        </p>

        <p>
          Need assistance? We're happy to provide it via email:{" "}
          <a href="mailto:support@jazzgroove.org">support@jazzgroove.org</a>,
          chat when available:{" "}
          <Link to="#" onClick={window.openChat}>
            LIVE ASSISTANCE
          </Link>{" "}
          on our website or toll free <a href="tel:8337895299">833-789-JAZZ</a>{" "}
          (5299).
        </p>
        {!props.print && (
          <button
            className="standard print-page"
            onClick={() => window.open("/tips/print", "_blank")}
          >
            Open Printable Page
          </button>
        )}
      </div>
    </div>
  );
}

export const HelpfulTipsPage = (props) => {
  let mobileOperatingSystem = isRedirectedFromMobileApp();
  
  useEffect(() => {
    if (props.print)
      swal({
        title: "Finish Printing",
        buttons: {
          yes: {
            text: "Print",
            value: true,
          },
          no: {
            text: "Cancel",
            value: false,
          },
        },
      }).then((res) => {
        if (res) {
          window.print();
          window.close();
        } else {
          window.close();
        }
      });
  }, [props.print]);
  return (
    <div className={mobileOperatingSystem ? "mobile-help page" : "order-confirmation page"}>
      <Title value={mobileOperatingSystem ? "User Guide" : "Helpful Tips"} />
      <h1 className="title">{mobileOperatingSystem ? "User Guide" : "Helpful Tips"}</h1>
      <HelpfulTips mobileOperatingSystem={mobileOperatingSystem} print={props.print} />
    </div>
  );
};
