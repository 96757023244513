import React from "react";
import { Link } from "react-router-dom";

const Nav = (props) => {
  return (
    <nav aria-label="footer-nav">
      <ul className="f-navigation">
        <li>
          <Link to="/" aria-label="Home">
            <i className="fas fa-home" />
          </Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/jazz-tonight">Jazz Tonight</Link>
        </li>
        <li>
          <Link to="/premium">Get Premium</Link>
        </li>
        <li>
          <Link to="#" onClick={window.openChat}>
            Help Desk
          </Link>
        </li>
        <li>
          <Link to="/listen">Ways to Listen</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/faqs">FAQS</Link>
        </li>
        <li>
          <a
            href="https://careasy.org/nonprofit/foundation-to-advance-jazz--jazz-groove"
            target="_blank"
            rel="noopener noreferrer"
          >
            Donate A Car
          </a>
        </li>
        <li>
          <Link
            to="#"
            onClick={() => {
              // bugsnagClient.notify(new Error("User Diagnostic"), {
              //   metaData: {
              //     logs: window.logs.map((a) => JSON.stringify(a)).join("\n"),
              //   },
              // });
            }}
          >
            Send Diagnostic
          </Link>
        </li>
      </ul>
      <ul className="f-navigation apps">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/the-jazz-groove/id1359980261"
            aria-label="Jazz Groove iOS App"
          >
            <img
              src="https://assets.jazzgroove.org/app-store-badge.png"
              alt="Jazz Groove iOS App"
            />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.thejazzgroove.radio&hl=en_US&gl=US"
            aria-label="Jazz Groove Android App"
          >
            <img
              src="https://assets.jazzgroove.org/google-play-badge.png"
              alt="Jazz Groove Android App"
            />
          </a>
        </li>
      </ul>
      <ul className="f-navigation social">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/TheJazzGroove"
            aria-label="Jazz Groove Facebook"
          >
            <i className="fab fa-facebook" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
