import Announcement from "Pages/Components/Announcement";
import Footer from "Pages/Components/Footer/Footer";
import Header from "Pages/Components/Header/Header";
import Player from "Pages/Components/Player/Player";
import Spinner from "Pages/Components/Spinner";
import Checkout from "Pages/Checkout";
import ClaimGift from "Pages/ClaimGift";
import ThankYou, { HelpfulTipsPage } from "Pages/ThankYou";
import { Redirect, Route, Switch } from "react-router-dom";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import PrivateRoute from "Utils/Routing/PrivateRoute";
import SonosLander from "../../Pages/Sonos/SignUp";
import SonosCheckout from "../../Pages/Sonos/Checkout";
import SonosComplete from "../../Pages/Sonos/Complete";
import { useAuth0 } from "react-auth0-spa";
import History from "Utils/Routing/History";
import retry from "Utils/ImportRetry";
import { isRedirectedFromMobileApp } from "Utils/RedirectedFromMobileApp";

const Home = React.lazy(() => retry(() => import("Pages/Home")));
const Blog = React.lazy(() => retry(() => import("Pages/Blog")));
const About = React.lazy(() => retry(() => import("Pages/About")));
const Contact = React.lazy(() => retry(() => import("Pages/Contact")));
const Premium = React.lazy(() => retry(() => import("Pages/Premium")));
const Listen = React.lazy(() => retry(() => import("Pages/Listen")));
const JazzTonight = React.lazy(() => retry(() => import("Pages/JazzTonight")));
const JazzTonightGallery = React.lazy(() =>
  retry(() => import("Pages/JazzTonightGallery"))
);
const Account = React.lazy(() => retry(() => import("Pages/Account")));
const LoginSuccessful = React.lazy(() =>
  retry(() => import("Pages/LoginSuccessful"))
);
const Error404 = React.lazy(() => retry(() => import("Pages/Error")));
const PrivacyPolicy = React.lazy(() =>
  retry(() => import("Pages/PrivacyPolicy"))
);
const Post = React.lazy(() => retry(() => import("Pages/Post")));
const Surround = React.lazy(() => retry(() => import("Pages/Surround")));
const Upgrade = React.lazy(() => retry(() => import("Pages/Upgrade")));
const BLM = React.lazy(() => retry(() => import("Pages/BLM")));

const contentful = require("contentful");
const client = contentful.createClient({
  space: "fskry955olww",
  accessToken: "Nslfnwp2wop2jaFRnHuF7hyxNDphAdEjs2KdleQYSN0",
});

const LoginRedirect = () => {
  const { loading, loginWithRedirect, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      History.push("/");
    } else if (!loading) loginWithRedirect();
  }, [loading, loginWithRedirect, isAuthenticated]);
  return null;
};

const SignUpRedirect = () => {
  const { loading, loginWithRedirect, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) History.push("/");
    else if (!loading) loginWithRedirect({ screen_hint: "signup" });
  }, [loading, loginWithRedirect, isAuthenticated]);
  return null;
};

const Routes = () => {
  const [announcementVisible, setAnnouncementVisible] = useState(false);
  const [fundDriveEnabled, setFundDriveEnabled] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 800);
  const [announcement, setAnnouncement] = useState({});

  useEffect(() => {
    function checkScreenSize() {
      setSmallScreen(window.innerWidth < 800);
    }
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    (async () => {
      const entry = await client.getEntry("2VcHCgVAr5sk50WFwrE2pH");
      if (entry.fields.announcementEnabled && !isRedirectedFromMobileApp()) {
        setAnnouncement({ ...entry.fields });
        setAnnouncementVisible(true);
        setFundDriveEnabled(entry.fields.fundDriveEnabled);
      }
    })();
  }, []);

  return (
    <Switch>
      <Route exact path={"/stream"} component={Player} />
      <Route exact path="/donate">
        <Redirect to="/checkout" />
      </Route>
      <Route path="/checkout" component={Checkout} />
      <Route
        exact
        path="/tips/print"
        render={() => {
          return <HelpfulTipsPage print={true} />;
        }}
      />
      <Route exact path={"/link/sonos"} component={SonosLander} />
      <Route exact path={"/link/sonos/complete"} component={SonosComplete} />
      <Route exact path={"/link/sonos/checkout"} component={SonosCheckout} />
      <Fragment>
        <Announcement
          setVisible={setAnnouncementVisible}
          visible={announcementVisible}
          {...announcement}
          fundDriveEnabled={fundDriveEnabled && !smallScreen}
        />
        
        <div
          className={
            "flex-container" +
            (announcementVisible ? " announcement-shown" : "") +
            (fundDriveEnabled && !smallScreen ? " fd-enabled" : "")
          }
        >
          {!isRedirectedFromMobileApp() && <Player />}
          <main>
            <Header />
            <Pages />
            <Footer />
          </main>
        </div>
      </Fragment>
    </Switch>
  );
};

const Pages = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route
          exact
          path={"/(devices|device)"}
          render={() => {
            return (
              <iframe
                className="faqs-doc-embed"
                title="Devices"
                src="https://docs.google.com/document/d/e/2PACX-1vSi4uKb714WC759Md5Dp3ppgEAf0DLU7KpPp0O8lfT1vrsXOd5gfCPM3KesQR0bN-6PVfLY7m5ipB5-/pub?embedded=true"
              ></iframe>
            );
          }}
        />
        <Route
          exact
          path={"/(faqs|facts)"}
          render={() => {
            return (
              <iframe
                className="faqs-doc-embed"
                title="FAQs"
                src="https://docs.google.com/document/d/e/2PACX-1vQaBkDbkYBKQjLmZJv0MbeQUjFkiyK78K8MC63h3ZBGfMlyDfvwaG_Ym1nmqJuk5NUHnhQ3mUZGWBt8/pub?embedded=true"
              ></iframe>
            );
          }}
        />
        <Route
          exact
          path={"/cap"}
          render={() => {
            return (
              <iframe
                className="faqs-doc-embed"
                title="Cap"
                src="https://docs.google.com/document/d/e/2PACX-1vQv1AQV1E76LdwlLK1dVpQaELuwImjJDpkYS-fJQ2KTwhnpytWb8diSoTPzi7OfZg7BM63saBgFu-b-/pub?embedded=true"
              ></iframe>
            );
          }}
        />
        <Route
          exact
          path={"/silence"}
          render={() => {
            return (
              <iframe
                className="faqs-doc-embed"
                title="Silence Fund Drives"
                src="https://docs.google.com/document/d/e/2PACX-1vQ61v7h7WjUScKjo6oZ3njqZuzCRS26gwT1-oyXb8cCTUQ-6KcQVqprK9UcFWEMzNFP7qilLHVgo00q/pub?embedded=true"
              ></iframe>
            );
          }}
        />
        <Route
          exact
          path={"/mcafee"}
          render={() => {
            return (
              <iframe
                className="faqs-doc-embed"
                title="McAfee"
                src="https://docs.google.com/document/d/e/2PACX-1vT3bbjTVWa6mpabFQMrMOdW03bO-GkEnoFC2M6v67wsmzocSmusCvUbuxMh1xK2sGCeWAIuPL2oAMG7/pub?embedded=true"
              ></iframe>
            );
          }}
        />
        <Route
          exact
          path={"/(echo|alexa|bluesound|cambridge|iphone|marantz|squeezebox|roon|rune|sonos|roku)"}
        >
          <Redirect to={"/devices"}/>
        </Route>
        <Route exact path={"/"} component={Home} />
        <Route
          exact
          path={"/:c(mix1|mix2|gems|dreams|smoothjazz.blue|christmas)"}
          component={Home}
        />
        <Route exact path={"/about"} component={About} />
        <Route exact path={"/login"} component={LoginRedirect} />
        <Route exact path={"/(signup|sign-up)"} component={SignUpRedirect} />
        <Route exact path={"/contact"} component={Contact} />
        <Route exact path={"/blog/:id"} component={Post} />
        <Route exact path={"/blog"} component={Blog} />
        <Route exact path={"/listen"} component={Listen} />
        <Route exact path={"/jazz-tonight"} component={JazzTonight} />
        <Route
          exact
          path={"/jazz-tonight/gallery"}
          component={JazzTonightGallery}
        />
        <Route exact path={"/premium"} component={Premium} />
        <PrivateRoute path={"/account"} component={Account} />
        <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
        <Route exact path="/(tips|help)" component={HelpfulTipsPage} />
        <Route exact path="/surround" component={Surround} />

        <Route exact path={"/success"} component={LoginSuccessful} />
        <Route exact path={"/thank-you"} component={ThankYou} />
        <Route exact path={"/blm"} component={BLM} />
        <Route exact path={"/(car|cars)"} component={Home} />
        <Route exact path={"/gift/claim/:id"} component={ClaimGift} />
        <Route exact path={"/upgrade/:id"} component={Upgrade} />
        <Redirect
          from={"/upgrade"}
          to={"/checkout/payment?a=1000&m=1&s=1&c=plus-spring20"}
        />
        <Route exact path={"/404"} component={Error404} />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
