import React from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";

const Footer = (props) => {
  return (
    <footer className="page-footer">
      <Nav />
      <div className="bottom-text">
        <p className="foundation">Foundation To Advance Jazz</p>
        <p className="nonprofit">
          501(c)(3) nonprofit organization
        </p>
        <p className="thanks">
          <a
            href="https://careasy.org/nonprofit/foundation-to-advance-jazz--jazz-groove"
            target="_blank"
            rel="noopener noreferrer"
          >
            Donate A Car
          </a>
        </p>
        <p className="thanks">
          Special thanks to: The Estate of Miles Davis, the Estate of Nat King
          Cole, the Estate of Chet Baker, iconic photographer{" "}
          <a
            href="https://donhunstein.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Don Hunstein
          </a>
          ,{" "}
          <a
            href="http://johnabbottphoto.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            celebrated photographer John Abbott
          </a>
          , <a href="https://indexcom.com/">Modulation Index</a>,{" "}
          <a
            href="https://charicomm.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Charicomm.org
          </a>
          ,{" "}
          <a
            href="https://www.4imprint.com/?mkid=ShopNowJR"
            target="_blank"
            rel="noopener noreferrer"
          >
            4imprint
          </a>
          ,{" "}
          <a
            href="https://www.rizzoliusa.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Rizzoli New York
          </a>
          ,{" "}
          <a
            href="https://www.instagram.com/dalajlampa/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dusan Rozic
          </a>
          , and all of the fantastic artists who record the music we play in
          which the world luxuriates.
        </p>
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>{" "}
        </p>
        {/* The Co-Browse code is taken from the css element #upscope-support-code, that element name and it's availability is changed in the upscope admin settings*/}
        <p className="upscope-footer">
          Co-Browse Code: <span id="upscope-support-code"></span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
