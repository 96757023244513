import React, { useEffect, useState } from "react";
import queryString from "query-string";
import Axios from "axios";
import * as Display from "../Utils/Display";
import { Link } from "react-router-dom";
import Spinner from "./Components/Spinner";
import Title from "./Components/Title";
import { getDonationPlan } from "./Components/Checkout/SelectAmount";
import History from "Utils/Routing/History";
import { CHECKOUT_ENDPOINT } from "Utils/Constants";

const PayPalCheckout = (props) => {
  const [donation, setDonation] = useState({
    gift: {},
  });
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [coupon, setCoupon] = useState({});
  useEffect(() => {
    let values = queryString.parse(props.location.search);
    Axios.get(
      process.env.REACT_APP_API_ENDPOINT +
        "/checkout/paypal-token?token=" +
        values.token
    )
      .then((response) => {
        setDonation(response.data);
        if (response.data.coupon) {
          Axios.post(process.env.REACT_APP_API_ENDPOINT + "/checkout/coupon", {
            coupon: response.data.coupon.id,
            plan:
              getDonationPlan(response.data.monthly, response.data.amount) +
              (response.data.isGift ? "-gift" : ""),
          })
            .then((r) => {
              setCoupon({
                id: response.data.coupon.id,
                formatted:
                  r.data.discountType === "fixed_amount"
                    ? "$" +
                      Display.formatDonation(
                        Math.min(response.data.amount, r.data.discountAmount) /
                          100
                      )
                    : r.data.discountAmount + "%",
                discountAmount: r.data.discountAmount,
                discountType: r.data.discountType,
              });
              setLoading(false);
            })
            .catch(()=>{
              setLoading(false);
            });
        }
        else setLoading(false);
      })
      .catch(() => {
        setError(
          "Uh oh! Your PayPal token has expired. Click here to make a new donation."
        );
        setLoading(false);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading)
    return (
      <div className="paypal-confirmation page">
        <Title value="Confirm Donation" />
        <h1 className="title">Confirm Your Donation</h1>

        <section className="step-box">
          <header>
            <h2>STEP 3:</h2>
            <h2>PayPal Order Summary</h2>
          </header>

          <div className="order-summary" id="summary-box">
            <div>
              <p className="summary-overline">Loading your donation...</p>
            </div>
          </div>
        </section>
      </div>
    );
  if (error)
    return (
      <div className="paypal-confirmation page">
        <h1 className="title">An error has occurred</h1>
        <div className="contained-content">
          <p className="body">
            <Link to="/checkout">{error}</Link>
          </p>
        </div>
      </div>
    );
  return (
    <div className="paypal-confirmation page">
      <Title value="Confirm Donation" />
      <h1 className="title">Confirm Your Donation</h1>

      <section className="step-box">
        <header>
          <h2>FINAL STEP:</h2>
          <h2>PayPal Order Summary</h2>
        </header>

        <div className="order-summary" id="summary-box">
          <div>
            <p className="summary-overline">Your donation:</p>
            <p className="summary-donation">
              A {donation.monthly ? "monthly" : "one time"} payment of $
              {Display.formatDonation(donation.amount / 100)}
            </p>
            {coupon.formatted && (
              <p className="summary-donation">{coupon.formatted}</p>
            )}
          </div>
          {donation.isGift && (
            <div>
              <p className="summary-overline">Gift Membership:</p>
              <p className="summary-donation">
                To: {donation.giftRecipient.first_name}
              </p>
            </div>
          )}
          {donation.gift && (
            <div>
              <h4>Your Gift:</h4>
              <p>{donation.gift.name}</p>
              {donation.size && (
                <p>
                  <span>Size: {donation.size}</span>
                </p>
              )}
              {donation.gift.meta_data &&
                donation.gift.meta_data.images &&
                donation.gift.meta_data.images[1] && (
                  <img
                    src={donation.gift.meta_data.images[1].url}
                    alt="gift"
                    className="paypal_gift-image"
                  />
                )}
            </div>
          )}
          {props.gift && props.gift.name === "No gift for me" && (
            <div>
              <p className="summary-overline">Your gift:</p>
              <p className="summary-gift">
                100% of donation supports <Link to="/">JazzGroove.org</Link> (no
                gift)
              </p>
            </div>
          )}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSubmitLoading(true);
              setSubmitError("");
              Axios.post(process.env.REACT_APP_API_ENDPOINT + CHECKOUT_ENDPOINT, {
                customer: {
                  email: donation.email,
                  first_name: donation.first_name,
                  last_name: donation.last_name,
                },
                plan: donation.plan,
                amount: donation.amount,
                autoRenew: donation.autoRenew,
                coupon: coupon.id,
                gift: donation.gift ? donation.gift.id : undefined,
                intl: donation.intl,
                shipping: donation.shipping,
                monthly: donation.monthly,
                giftRecipient: donation.giftRecipient,
                isGift: donation.isGift,
                paymentMethod: {
                  type: "paypal_express_checkout",
                  token: donation.id,
                },
                dev: process.env.REACT_APP_NODE_ENV === "dev",
              })
                .then((response) => History.push("/thank-you", response.data))
                .catch((e) => {
                  setSubmitLoading(false);
                  setSubmitError(
                    e.response && e.response.data
                      ? e.response.data.message
                      : e.message
                      ? e.message
                      : e
                  );
                });
            }}
          >
            <button className="standard" disabled={submitLoading}>
              {submitLoading ? <Spinner /> : <span>Complete Donation</span>}
            </button>
            {submitError && <p className="form-error-message">{submitError}</p>}
          </form>
        </div>
      </section>
    </div>
  );
};

export default PayPalCheckout;
