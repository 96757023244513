import React, { Fragment, useState } from "react";
import swal from "../../Components/Swal";
import axios from "axios";
import * as Display from "Utils/Display";
import moment from "moment";
import PropTypes from "prop-types";
import Axios from "axios";

export function getCleanPlan(id) {
  switch (id) {
    case "premium-plus":
      return "JazzGroove Premium Plus";
    case "premium-plus-gift":
      return "JazzGroove Premium Plus Gift";
    case "premium-plus-monthly":
      return "JazzGroove Premium Plus (Monthly)";
    case "unlimited-listening":
      return "JazzGroove Unlimited Listening";
    case "jazzgroove-premium-plus-2019-summer":
      return "JazzGroove Premium Plus (Summer 2019)";
    case "jazzgroove-premium-annual":
      return "JazzGroove Premium (Annual)";
    case "jazzgroove-premium":
      return "JazzGroove Premium";
    case "jazzgroove-premium-monthly":
      return "JazzGroove Premium (Monthly)";
    case "jazzgroove-donation-only-monthly":
      return "JazzGroove Donation Only (Monthly)";
    case "jazzgroove-donation-only":
      return "JazzGroove Donation Only";
    case "jazzgroove-premium-plus-2019-fall":
      return "JazzGroove Premium Plus (Fall 2019)";
    default:
      return "";
  }
}

function isActive(status, remainingBillingCycles, plan) {
  return (
    (status === "active" || status === "in_trial") &&
    plan !== "unlimited-listening"
  );
}

export function daysUntil(futureDate) {
  return parseInt(
    (futureDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
  );
}

const Subscription = (props) => {
  const [showModify, setShowModify] = useState(false);
  const [price, setPrice] = useState(props.price);
  const endDate = getEndDate(
    props.status,
    props.remainingBillingCycles,
    props.billingPeriodUnit,
    props.termEnd,
    props.planId
  );
  return (
    <div className="donation-history-item">
      <div className="heading-row">
        <h3>{getCleanPlan(props.planId)}</h3>
        {isActive(props.status, props.remainingBillingCycles, props.planId) && (
          <p className="active badge">ACTIVE</p>
        )}
        {props.status === "cancelled" && (
          <p className="cancelled badge">CANCELLED</p>
        )}
        {props.status === "non_renewing" && (
          <p className="expiring badge">
            EXPIRES IN {daysUntil(new Date(endDate.time))} DAY(S)
          </p>
        )}
        {price > 0 && <p>Amount: ${Display.formatDonation(price / 100)}</p>}
      </div>
      <ul>
        <li>
          Start Date: {moment.unix(props.activatedAt).format("MMMM Do, YYYY")}
        </li>
        <li>{endDate.text + endDate.date}</li>
      </ul>
      {isActive(props.status, props.remainingBillingCycles, props.planId) && (
        <Fragment>
          <div className="flex">
            <button
              className={showModify ? "modify-button active" : "modify-button"}
              onClick={() => setShowModify((m) => !m)}
            >
              Modify donation amount
            </button>
            <CancelButton
              id={props.id}
              termEnd={props.termEnd}
              onSubmit={props.onModify}
            />
          </div>
        </Fragment>
      )}
      {props.status === "non_renewing" &&
        !(
          props.planId === "unlimited-listening" ||
          props.planId === "jazzgroove-premium-plus-2019-summer"
        ) && (
          <button
            className="standard"
            onClick={() => {
              Axios.post(
                process.env.REACT_APP_API_ENDPOINT + "/donate/reactivate",
                { id: props.id }
              ).then(() => {
                swal("Successfully updated subscription.");
                props.onModify();
              });
            }}
          >
            Renew
          </button>
        )}
      {showModify && (
        <div className="update-donation-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (price >= 1) {
                modifyPlan({ ...props }, price, props.onModify);
              } else swal("New amount must be greater than or equal to $1");
            }}
          >
            <div className="currency-wrapper">
              <p className="currency-sign">$</p>
              <input
                type="text"
                value={Display.formatDonation(price / 100)}
                onChange={(e) => {
                  const value = e.target.value.replace(
                    /*eslint-disable-next-line no-useless-escape*/
                    /[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
                    ""
                  );
                  if (isNaN(value)) return;
                  if (value < 0) setPrice(100);
                  else setPrice(value * 100);
                }}
                required
                maxLength={13}
              />
            </div>
            <button className="primary-button">Update donation</button>
          </form>
        </div>
      )}
    </div>
  );
};

Subscription.propTypes = {
  price: PropTypes.number.isRequired,
  planId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onModify: PropTypes.func.isRequired,
  activatedAt: PropTypes.number.isRequired,
  status: PropTypes.oneOf(["active", "non_renewing", "cancelled", "in_trial"])
    .isRequired,
  billingPeriodUnit: PropTypes.oneOf(["month", "year"]).isRequired,
  termEnd: PropTypes.number.isRequired,
  remainingBillingCycles: PropTypes.number.isRequired,
};

export default Subscription;

export const CancelButton = (props) => {
  return (
    <Fragment>
      <button
        onClick={() =>
          window.open(
            "https://jazzgroove.chargebeeportal.com/portal/v2/login?forward=home"
          )
        }
        className="tertiary-button"
      >
        Update payment method
      </button>
      <button
        onClick={() => cancelPlan(props.id, props.termEnd, props.onSubmit)}
        className="tertiary-button"
      >
        Cancel plan
      </button>
    </Fragment>
  );
};

CancelButton.propTypes = {
  id: PropTypes.string.isRequired,
  termEnd: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
};

export function modifyPlan(subscription, newAmount, onSubmit, coupon) {
  swal({
    title: "Confirm Changes",
    text:
      "Switch to $" +
      Display.formatDonation(newAmount / 100) +
      "/" +
      subscription.billingPeriodUnit +
      (newAmount > subscription.plan_unit_price
        ? "\nYou will be invoiced a prorated amount today."
        : "\nChanges will take effect on your next invoice."),
    buttons: {
      yes: {
        text: "Confirm",
        value: true,
      },
      no: {
        text: "Cancel",
        value: false,
      },
    },
  }).then((res) => {
    if (res) {
      swal({ title: "Loading...", buttons: false });
      axios
        .post(
          process.env.REACT_APP_API_ENDPOINT + "/donate/modify",
          {
            subscription: subscription,
            newAmount: newAmount,
            coupon: coupon,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((response) => {
          if (onSubmit) onSubmit();
          swal("Successfully updated subscription");
        })
        .catch((e) =>
          swal({
            title: "An error has occurred.",
            text:
              e.response && e.response.data
                ? e.response.data.message
                : e.message
                ? e.message
                : e,
          })
        );
    }
  });
}

export function cancelPlan(id, termEnd, onSubmit) {
  swal({
    title: "Are you sure you want to cancel this subscription?",
    text:
      "Plan will be active until " +
      new Date(termEnd * 1000).toLocaleDateString("en-us"),
    buttons: {
      yes: {
        text: "Yes, cancel",
        value: id,
      },
      no: {
        text: "No, keep",
        value: false,
      },
    },
  }).then((res) => {
    if (res) {
      axios
        .post(
          process.env.REACT_APP_API_ENDPOINT + "/donate/cancel",
          {
            subscriptionId: res,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then(() => {
          if (onSubmit) onSubmit();
          swal("Successfully cancelled plan.");
        })
        .catch(() => swal("An error has occurred."));
    }
  });
}

export function getEndDate(
  status,
  remainingBillingCycles,
  billingPeriodUnit,
  termEnd,
  planId
) {
  const date = remainingBillingCycles
    ? moment.unix(termEnd).add(remainingBillingCycles, billingPeriodUnit)
    : moment.unix(termEnd);
  return {
    text: isActive(status, remainingBillingCycles, planId)
      ? "Next Billing Date: "
      : "End Date: ",
    time: date,
    date: date.format("MMMM Do, YYYY"),
  };
}
