/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Slider from "rc-slider";
import * as Display from "Utils/Display";

class Announcement extends Component {
  getButton() {
    if (!this.props.buttonEnabled) {
    } else if (this.props.buttonUrl.includes("https://jazzgroove.org")) {
      return (
        <Link
          style={{
            color: "#fff",
            background: "#276DB2",
            borderRadius: "5px",
            padding: "3px 12px",
            margin: "0px 10px",
            fontSize: "15px",
            top: "-1px",
            position: "relative",
          }}
          to={this.props.buttonUrl.replace("https://jazzgroove.org", "")}
        >
          {this.props.buttonText}
        </Link>
      );
    } else {
      return (
        <a
          style={{
            color: "#fff",
            background: "#276DB2",
            borderRadius: "5px",
            padding: "3px 12px",
            margin: "0px 10px",
            fontSize: "15px",
            top: "-1px",
            position: "relative",
          }}
          href={this.props.buttonUrl}
        >
          {this.props.buttonText}
        </a>
      );
    }
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <aside
        className="announcement"
        aria-label="Announcement bar"
        style={this.props.fixed && { position: "fixed" }}
      >
        <div className="scroll-left">
          {this.props.fundDriveEnabled ? (
            <h1>
              {this.props.content}
              <Slider min={0} step={1} max={100000} value={this.props.fundDriveRaised}/>
              ${Display.formatDonation(this.props.fundDriveRaised)} Raised | Goal:
              ${Display.formatDonation(this.props.fundDriveGoal)}
              <Link
                style={{
                  color: "#fff",
                  background: "#276DB2",
                  borderRadius: "5px",
                  padding: "3px 12px",
                  margin: "0px 10px",
                  fontSize: "15px",
                  top: "-1px",
                  position: "relative",
                }}
                to="/checkout"
                onClick={this.props.hideAnnouncement}
              >
                DONATE
              </Link>
            </h1>
          ) : (
            <h1>
              {this.props.content}
              {this.getButton()}
            </h1>
          )}
        </div>
        <button
          className={"close"}
          onClick={() => this.props.setVisible(false)}
        >
          ×
        </button>
      </aside>
    );
  }
}

export default withRouter(Announcement);
