import React, { useState, Fragment, useEffect, useCallback } from "react";
import { Route, Switch } from "react-router";
import Summary from "./Components/Checkout/Summary";
import Payment from "./Components/Checkout/Payment";
import Shipping from "./Components/Checkout/Shipping";
import AnchorLink from "react-anchor-link-smooth-scroll";
import SelectAmount, {
  getDonationPlan,
} from "./Components/Checkout/SelectAmount";
import Coupon from "./Components/Checkout/Coupon";
import SelectGift from "./Components/Checkout/SelectGift";
import Breadcrumbs from "./Components/Checkout/Breadcrumbs";
import Contact from "./Components/Checkout/Contact";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Axios from "axios";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import queryString from "query-string";
import Helmet from "react-helmet";
import { isMobile } from "react-device-detect";
import PayPalCheckout from "./PayPalCheckout";
import * as Display from "Utils/Display";
import ContentfulClient from "Utils/ContentfulClient";
import History from "Utils/Routing/History";
import { useAuth0 } from "react-auth0-spa";
import { CHECKOUT_ENDPOINT } from "Utils/Constants";
import swal from "./Components/Swal";

export const defaultGift = {
  id: "",
  name: "No gift for me",
  first: true,
  caption:
    "I'd like 100% of my donation to support JazzGroove.org and be tax deductible.",
  is_shippable: false,
  meta_data: {},
  description:
    "I'd like 100% of my donation to support JazzGroove.org and be tax deductible.",
};
const DonationStep = (props) => {
  return (
    <section className="step-box">
      <header>
        <h2>Step {props.step}:</h2>
        <h2>{props.title}</h2>
        {props.step !== 1 && props.goBack && (
          <div className="go-back">
            <button className="standard" onClick={props.goBack}>
              Go Back
            </button>
          </div>
        )}
      </header>
      {props.children}
    </section>
  );
};

//query values: a: (int) amount, m: (bool) monthly, gm: (bool) isGift, c: (str) coupon, g: (str) default gift, s: (bool) start at payment step
export const getQueryStringValue = (key, qs = window.location.search) => {
  const values = queryString.parse(qs);
  return values[key];
};

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CheckoutWithElements = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState(
    parseInt(getQueryStringValue("a")) || 10000
  );
  const [monthly, setMonthly] = useState(
    getQueryStringValue("m")
      ? Boolean(parseInt(getQueryStringValue("m")))
      : false
  );
  const [autoRenew, setAutoRenew] = useState(true);
  const [isGift, setIsGift] = useState(
    Boolean(parseInt(getQueryStringValue("gm"))) || false
  );
  const [giftRecipient, setGiftRecipient] = useState({
    first_name: "",
    last_name: "",
    email: "",
    note: "",
    sendNow: true,
    date: undefined,
  });
  const [gift, setGift] = useState(defaultGift);
  const [gifts, setGifts] = useState([defaultGift]);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [plaidToken, setPlaidToken] = useState(null);
  const [content, setContent] = useState("");
  const [topText, setTopText] = useState("");
  const [customer, setCustomer] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [captcha, setCaptcha] = useState(
    process.env.NODE_ENV === "production" ? undefined : "abc123"
  );
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      text: "Select Monthly, One-time, or Gift Membership & Amount",
      mobile: "Select Plan & Amount",
      step: 1,
      route: "/checkout",
    },
  ]);
  const [address, setAddress] = useState({
    country: "US",
  });
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [error, setError] = useState("");
  const [additional, setAdditional] = useState(0);
  const [qGift] = useState(getQueryStringValue("g") || "");
  const [completedStep, setCompletedStep] = useState(
    getQueryStringValue("s") ? 1 : 0
  );
  const [card, setCard] = useState(null);

  const { isAuthenticated, user } = useAuth0();

  const selectGift = useCallback(
    (g) => {
      if (!coupon.applied || (coupon.applied && g.id === "")) {
        if (
          g.meta_data &&
          g.meta_data.pricing &&
          g.meta_data.pricing[monthly ? "monthly" : "oneTime"] &&
          g.meta_data.pricing[monthly ? "monthly" : "oneTime"].minimum > amount
        ) {
          setAmount(
            g.meta_data.pricing[monthly ? "monthly" : "oneTime"].minimum
          );
        }
        setGift(g);
      } else {
        swal({
          title:
            "Gifts are not eligible when using coupons. Would you like to remove your coupon and select this gift?",
          showCloseButton: true,
          className: "custom-swal-modal",
          buttons: {
            close: {
              text: "No",
              value: 0,
            },
            choose: {
              text: "Yes",
              value: 1,
            },
          },
        }).then((res) => {
          if (res) {
            setCoupon({})
            if (
              g.meta_data &&
              g.meta_data.pricing &&
              g.meta_data.pricing[monthly ? "monthly" : "oneTime"] &&
              g.meta_data.pricing[monthly ? "monthly" : "oneTime"].minimum >
                amount
            ) {
              setAmount(
                g.meta_data.pricing[monthly ? "monthly" : "oneTime"].minimum
              );
            }
            setGift(g);
          }
        });
      }
    },
    [setAmount, setGift, monthly, amount, coupon, setCoupon]
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      !Object.keys(customer).filter((key) => customer[key]).length
    ) {
      setCustomer({
        first_name: user ? user.given_name : "",
        last_name: user ? user.family_name : "",
        email: user ? user.email : "",
      });
    }
  }, [isAuthenticated, user, customer]);

  useEffect(() => {
    Axios.post(
      process.env.REACT_APP_API_ENDPOINT + "/donate/gifts",
      {
        planId: "*",
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        setGifts([defaultGift].concat(response.data));
        if (qGift) {
          response.data.forEach((g) => {
            if (g.id === qGift) {
              selectGift(g);
            } else if (g.meta_data.variants) {
              g.meta_data.variants.forEach((v) => {
                if (v.id === qGift) {
                  selectGift({ ...g, size: v });
                }
              });
            }
          });
        }
      })
      .catch(() => {});
      // eslint-disable-next-line
  }, [qGift]);

  useEffect(() => {
    ContentfulClient.getEntry("RrO9gJJmG55P6vX5Dw65y")
      .then((entry) => {
        const options = {
          renderNode: {
            [INLINES.HYPERLINK]: (node, next) =>
              `<a href="${node.data.uri}"${
                node.data.uri.startsWith("#") ? "" : ' target="_blank"'
              }>${next(node.content)}</a>`,
            [BLOCKS.PARAGRAPH]: (node, next) =>
              `<p class="body">${next(node.content)}</p>`,
          },
        };
        setContent(documentToHtmlString(entry.fields.content, options));
        setTopText(entry.fields.topText);
      })
      .catch(console.error);
  }, []);
  useEffect(() => {
    History.listen((location) => {
      if (location.pathname !== props.location.pathname) {
        window.scrollTo(0, 0);
      }
    });
    //eslint-disable-next-line
  }, []);

  const submitDonation = async () => {
    setLoading(true);
    try {
      switch (paymentMethod) {
        case 0:
          if (!captcha) {
            throw new Error("Please complete the captcha.");
          }
          let paymentIntent;
          const x =
            card ||
            (await stripe.createPaymentMethod({
              billing_details: {
                name: customer.first_name + " " + customer.last_name,
                email: customer.email,
              },
              type: "card",
              card: elements.getElement(CardNumberElement),
            }));
          const paymentIntentResponse = await Axios.get(
            process.env.REACT_APP_API_ENDPOINT +
              `/donate/secret?payment_method=${x.paymentMethod.id}&amount=${
                amount + additional + (address.country !== "US" ? 1500 : 0)
              }`
          );
          if (paymentIntentResponse.data.statusCode !== 200) {
            // record paymentIntentResponse.body to sentry
            throw new Error(paymentIntentResponse.data.message);
          }
          const paymentIntentResult = await stripe.retrievePaymentIntent(
            paymentIntentResponse.data.body.secret
          );
          if (
            paymentIntentResult.paymentIntent.status ===
              "requires_confirmation" ||
            paymentIntentResult.paymentIntent.status === "requires_action"
          ) {
            const result = await stripe.confirmCardPayment(
              paymentIntentResponse.data.body.secret
            );
            if (result.error) throw new Error(result.error.message);
            paymentIntent = result.paymentIntent;
          } else {
            paymentIntent = paymentIntentResult.paymentIntent;
          }

          const cardResponse = await Axios.post(
            process.env.REACT_APP_API_ENDPOINT + CHECKOUT_ENDPOINT + "/v2",
            {
              captcha: captcha,
              customer: customer,
              shippingAddress: address,
              amount: amount + additional,
              paymentMethod: {
                type: "card",
                paymentIntent: paymentIntent,
              },
              autoRenew: autoRenew,
              monthly: monthly,
              plan: getDonationPlan(monthly, amount),
              coupon: coupon.applied ? coupon.value : undefined,
              shipping: gift.is_shippable,
              gift: gift && gift.size ? gift.size.id : gift.id,
              isGift: isGift,
              giftRecipient: giftRecipient,
              dev: process.env.REACT_APP_NODE_ENV === "dev",
            }
          );
          History.push("/thank-you", cardResponse.data);
          break;
        case 1:
          //paypal
          const paypalResponse = await Axios.post(
            process.env.REACT_APP_API_ENDPOINT + "/checkout/generate-paypal",
            {
              amount: amount + additional,
              coupon: coupon.applied ? coupon.value : "",
              gift: gift && gift.size ? gift.size.id : gift.id,
              autoRenew: autoRenew,
              plan: getDonationPlan(monthly, amount),
              customer: customer,
              monthly: monthly,
              shipping: gift.is_shippable,
              isGift: isGift,
              intl: address.country !== "US",
              giftRecipient: giftRecipient,
              dev: process.env.REACT_APP_NODE_ENV === "dev",
            }
          );
          window.open(paypalResponse.data.url, "_self");
          break;
        case 2:
          if (plaidToken.error) throw new Error(plaidToken.error.message);
          const achResponse = await Axios.post(
            process.env.REACT_APP_API_ENDPOINT + CHECKOUT_ENDPOINT,
            {
              customer: customer,
              shippingAddress: address,
              amount: amount + additional,
              paymentMethod: {
                type: "direct_debit",
                token: plaidToken.token,
                accountId: plaidToken.accountId,
              },
              autoRenew: autoRenew,
              monthly: monthly,
              plan: getDonationPlan(monthly, amount),
              coupon: coupon.applied ? coupon.value : undefined,
              shipping: gift.is_shippable,
              gift: gift && gift.size ? gift.size.id : gift.id,
              isGift: isGift,
              giftRecipient: giftRecipient,
              dev: process.env.REACT_APP_NODE_ENV === "dev",
            }
          );
          History.push("/thank-you", achResponse.data);
          break;
        default:
          setError("Invalid payment method");
      }
    } catch (e) {
      if (e.message === "token is undefined") {
        setError("Please enter valid payment information");
      } else {
        setError(
          e.response && e.response.data
            ? e.response.data.message
            : e.message
            ? e.message
            : e
        );
      }
    }
    setLoading(false);
  };
  return (
    <div className="page donate-main">
      <Helmet>
        <title>Jazz Groove - Donate</title>
      </Helmet>
      <header className="donate-main-header">
        <a href="/">
          <img
            src="https://assets.jazzgroove.org/logo-compact2B-white1.png"
            alt="jazz groove logo"
            className="header-logo"
          />
        </a>
      </header>
      <section className="subheader">
        <h1 id="back-to-top">Donate</h1>
        <h2>{topText}</h2>
        <AnchorLink href="#explanation" offset="100" className="readmore">
          Read More
        </AnchorLink>
      </section>

      {breadcrumbs.length && (
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          setBreadcrumbs={setBreadcrumbs}
        />
      )}

      <main className="donate-main-body">
        <Switch>
          <Route
            exact
            path="/checkout/paypal"
            render={(props) => {
              if (breadcrumbs.length) setBreadcrumbs([]);
              return <PayPalCheckout {...props} />;
            }}
          />
          <Fragment>
            <DonationStep
              goBack={() => {
                if (breadcrumbs.length) {
                  if (breadcrumbs[breadcrumbs.length - 1].step === 2)
                    History.push("/checkout");
                  else if (breadcrumbs[breadcrumbs.length - 1].step === 3)
                    History.push("/checkout/payment");
                }
              }}
              step={
                breadcrumbs.length && breadcrumbs[breadcrumbs.length - 1].step
              }
              title={
                breadcrumbs.length &&
                (isMobile && breadcrumbs[breadcrumbs.length - 1].mobile
                  ? breadcrumbs[breadcrumbs.length - 1].mobile
                  : breadcrumbs[breadcrumbs.length - 1].text)
              }
            >
              <Switch>
                <Route
                  exact
                  path="/checkout"
                  render={(props) => {
                    if (breadcrumbs.length !== 1)
                      setBreadcrumbs([
                        {
                          text: "Select Monthly, One-time, or Gift Membership & Amount",
                          mobile: "Select Plan & Amount",
                          step: 1,
                          route: "/checkout",
                        },
                      ]);
                    return (
                      <Fragment>
                        <SelectAmount
                          amount={amount}
                          isGift={isGift}
                          giftRecipient={giftRecipient}
                          setGiftRecipient={setGiftRecipient}
                          setAmount={(a) => {
                            if (a < amount) setCoupon({});
                            setAmount(a);
                          }}
                          setMonthly={setMonthly}
                          setIsGift={setIsGift}
                          monthly={monthly}
                          sendToPayment={() => {
                            setCompletedStep(1);
                            History.push("/checkout/payment");
                          }}
                          resetGift={() => setGift(defaultGift)}
                          giftDetails={gift}
                        />
                        <SelectGift
                          monthly={monthly}
                          gift={gift}
                          selectGift={selectGift}
                          gifts={gifts}
                          isGift={isGift}
                          sendToPayment={() => {
                            setCompletedStep(1);
                            History.push("/checkout/payment");
                          }}
                          setToOneTime={() => {
                            setAmount(10000);
                            setMonthly(false);
                            setCoupon({});
                          }}
                        />
                      </Fragment>
                    );
                  }}
                />
                <Route
                  exact
                  path="/checkout/payment"
                  render={() => {
                    if (breadcrumbs.length !== 2)
                      setBreadcrumbs([
                        {
                          text: "Select Monthly, One-time, or Gift Membership & Amount",
                          mobile: "Select Plan & Amount",
                          step: 1,
                          route: "/checkout",
                        },
                        {
                          text: "Your Contact & Payment Information",
                          step: 2,
                          route: "/checkout/payment/",
                        },
                      ]);
                    if (completedStep < 1) {
                      History.push("/checkout");
                    }
                    return (
                      <Fragment>
                        <form
                          onSubmit={async (e) => {
                            setError("");
                            e.preventDefault();
                            if (paymentMethod === 0) {
                              if (!captcha) {
                                setError("Please complete the captcha.");
                                return;
                              }
                              if (gift.is_shippable) {
                                const c = await stripe.createPaymentMethod({
                                  billing_details: {
                                    name:
                                      customer.first_name +
                                      " " +
                                      customer.last_name,
                                    email: customer.email,
                                  },
                                  type: "card",
                                  card: elements.getElement(CardNumberElement),
                                });
                                setCard(c);
                                History.push("/checkout/shipping");
                              } else submitDonation();
                              return;
                            } else if (paymentMethod === 2) {
                              if (
                                plaidToken &&
                                plaidToken.token &&
                                plaidToken.accountId
                              ) {
                                if (gift.is_shippable)
                                  History.push("/checkout/shipping");
                                else submitDonation();
                                return;
                              } else {
                                setError("Please link your bank account");
                              }
                            } else if (paymentMethod === 1) {
                              submitDonation();
                            }
                          }}
                        >
                          <Contact
                            customer={customer}
                            setCustomer={setCustomer}
                          />
                          {/* The coupon component below only appears on mobile view
                          This component is passed the exact same props as coupon component in
                          Summary.js, which is passed props from this checkout component.
                          This component was added here in order to prevent confusion for users
                          who could not find the coupon code in the dropdown on mobile.
                          This technical debt is willingly accepted due to the forthcoming UI refactor */}
                          <div className="hide-coupon-not-mobile">
                          <hr className="divider" />
                          <Coupon
                            coupon={coupon}
                            gift={gift}
                            setCoupon={setCoupon}
                            updateCoupon={(value) => setCoupon({ ...coupon, value: value })}
                            applyCoupon={() => {
                              return new Promise(async (resolve) => {
                                try {
                                  const response = await Axios.post(
                                    process.env.REACT_APP_API_ENDPOINT + "/checkout/coupon",
                                    {
                                      coupon: coupon.value,
                                      plan:
                                        getDonationPlan(monthly, amount) +
                                        (isGift ? "-gift" : ""),
                                    }
                                  );
                                  setCoupon({
                                    ...coupon,
                                    formatted:
                                      response.data.discountType === "fixed_amount"
                                        ? "$" +
                                          Display.formatDonation(
                                            Math.min(amount, response.data.discountAmount) /
                                              100
                                          )
                                        : response.data.discountAmount + "%",
                                    error: false,
                                    applied: true,
                                    discountAmount: response.data.discountAmount,
                                    discountType: response.data.discountType,
                                    message: (gift && (gift.id !== "")) ? "Gifts are not eligible to be used with a coupon. Your gift has been automatically removed. If you would like to add your gift back, please click 'REMOVE COUPON'." : ""
                                  });
                                  setGift({...defaultGift, fallbackGift: gift});
                                } catch (e) {
                                  setCoupon({ ...coupon, error: true });
                                }
                                resolve();
                              });
                            }}              remove={() => {
                              setCoupon({});
                              if (gift.fallbackGift) {
                                setGift(gift.fallbackGift);
                              }
                            }}
                          />
                          </div>
                          <hr className="divider" />
                          <Payment
                            shipping={gift.is_shippable}
                            isGift={isGift}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            plaidToken={plaidToken}
                            setPlaidToken={setPlaidToken}
                            setPlaidAccountId={(id) =>
                              setPlaidToken({ ...plaidToken, accountId: id })
                            }
                            address={address}
                            setAddress={setAddress}
                            loading={loading}
                            amount={amount}
                            setAutoRenew={setAutoRenew}
                            autoRenew={autoRenew}
                            captcha={captcha}
                            setCaptcha={setCaptcha}
                            error={error}
                            setError={setError}
                            setAdditional={setAdditional}
                            customer={customer}
                            stripe={stripe}
                            elements={elements}
                          />
                        </form>
                      </Fragment>
                    );
                  }}
                />
                <Route
                  exact
                  path="/checkout/shipping"
                  render={() => {
                    if (breadcrumbs.length !== 3)
                      setBreadcrumbs([
                        {
                          text: "Select Monthly, One-time, or Gift Membership & Amount",
                          mobile: "Select Plan & Amount",
                          step: 1,
                          route: "/checkout",
                        },
                        {
                          text: "Your Contact & Payment Information",
                          step: 2,
                          route: "/checkout/payment/",
                        },
                        {
                          text: "Shipping",
                          step: 3,
                          route: "/checkout/shipping/",
                        },
                      ]);
                    if (completedStep < 1) {
                      History.push("/checkout");
                    }
                    return (
                      <Shipping
                        address={address}
                        setAddress={setAddress}
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitDonation();
                        }}
                        error={error}
                        loading={loading}
                      />
                    );
                  }}
                />
              </Switch>
            </DonationStep>
            <Summary
              amount={amount + additional}
              shipping={gift.is_shippable}
              intl={address.country !== "US"}
              gift={gift}
              setGift={setGift}
              isGift={isGift}
              monthly={monthly}
              coupon={coupon}
              setCoupon={setCoupon}
              updateCoupon={(value) => setCoupon({ ...coupon, value: value })}
              hideTotal={breadcrumbs.length === 1}
              giftRecipient={giftRecipient}
              applyCoupon={() => {
                return new Promise(async (resolve) => {
                  try {
                    const response = await Axios.post(
                      process.env.REACT_APP_API_ENDPOINT + "/checkout/coupon",
                      {
                        coupon: coupon.value,
                        plan:
                          getDonationPlan(monthly, amount) +
                          (isGift ? "-gift" : ""),
                      }
                    );
                    setCoupon({
                      ...coupon,
                      formatted:
                        response.data.discountType === "fixed_amount"
                          ? "$" +
                            Display.formatDonation(
                              Math.min(amount, response.data.discountAmount) /
                                100
                            )
                          : response.data.discountAmount + "%",
                      error: false,
                      applied: true,
                      discountAmount: response.data.discountAmount,
                      discountType: response.data.discountType,
                      message: (gift && (gift.id !== "")) ? "Gifts are not eligible to be used with a coupon. Your gift has been automatically removed. If you would like to add your gift back, please click 'REMOVE COUPON'." : ""
                    });
                    setGift({...defaultGift, fallbackGift: gift});
                  } catch (e) {
                    setCoupon({ ...coupon, error: true });
                  }
                  resolve();
                });
              }}
            />
          </Fragment>
        </Switch>
      </main>
      <section
        id="explanation"
        className="explanation"
        dangerouslySetInnerHTML={{ __html: content }}
      ></section>
      <p className="upscope-on-checkout">
        Co-Browse Code: <span id="upscope-support-code"></span>
      </p>
    </div>
  );
};

const Checkout = (props) => {
  return (
    <Elements stripe={stripe}>
      <CheckoutWithElements {...props} />
    </Elements>
  );
};

export default Checkout;
