/* global AudioPump */
import ReactGA from "react-ga";
import moment from "moment";
import { isPremium, isPremiumPlus } from "./Utils/Account/Plan";
import { maxListenTime } from "./App";
import sendLog from "./Utils/Logger";
import {
  LOW_QUALITY,
  MID_QUALITY,
  HIGH_QUALITY,
  AUTH0_USER_METADATA_KEY,
} from "Utils/Constants";
import { getUser, lastUser, getTokenSilently } from "react-auth0-spa";
import Axios from "axios";
import { AUTH0_MANAGEMENT_AUDIENCE } from "Utils/Constants";
import * as Sentry from "@sentry/react";
import ContentfulClient from "Utils/ContentfulClient";

let awaitingRetry = false;
let retryCount = 0;
const retryLimit = 100;

let last = {
  playlistId: null,
  channelId: null,
};

let BLOCK_PLAYLIST_ID;
let BLOCK = false;
let subscriber = false;
let userId = null;
let currentTimeListened;
let listenerCapData = {}; 

//Get listening cap data from contentful, Dev/Test data is accessable from different entry id
// Dev Entry Id: 4bZ6EN9hAqMXu2rC1xW7HO
ContentfulClient.getEntry('49oXDWgQdSXcJzfpmmpXE4').then(data => {
  listenerCapData = data.fields
  
  currentTimeListened = localStorage.getItem("block-time")
  ? JSON.parse(localStorage.getItem("block-time"))
  : {
      listenTime: 0,
      resetTime: moment().endOf(listenerCapData.listeningCapTimeFrame.toLowerCase()),
    };

  if (moment(currentTimeListened.resetTime).isBefore()) {
    currentTimeListened.resetTime = moment().endOf(listenerCapData.listeningCapTimeFrame.toLowerCase());
    currentTimeListened.listenTime = 0;
  }

  BLOCK_PLAYLIST_ID = listenerCapData.listeningCapRedirectUrl.split('/').pop(); //Parses Digital Ocean URL for ID. e.g. example.com/ID
})
.catch(err => {
  //If contentful data inaccessible, fallback to hardcoded data
  console.error('Error getting Listener Cap Contentful data\n', err);

  listenerCapData.listeningCapTimeFrame = 'DAY';
  listenerCapData.listeningCapTimeLimit = 30;
});

/**
 * Keeps track of each minute a user has listened, caches this data
 * Checks to see if user has gone over listening cap limit
 * Returns without doing anything if user is a subscriber
 * @returns 
 */
function incrementMinutes() {
  if (subscriber) return;
  currentTimeListened.listenTime++;
  if (moment(currentTimeListened.resetTime).isBefore()) {
    currentTimeListened.resetTime = moment().endOf(listenerCapData.listeningCapTimeFrame.toLowerCase());
  }
  localStorage.setItem("block-time", JSON.stringify(currentTimeListened));
  try {
    checkBlock(true);
  } catch (e) {}
}

/**
 * Checks if the user is over the listening cap and/or is authenticated
 * If user is authenticated/subscribed or under the cap make no change
 * If the user is unsubscribed and over the cap change playlist to promo playlist
 * @param {Boolean} stopImmediately Flag to denote if player should switch immediately or only after cached songs are done playing
 * @param {Boolean} setSubscriber Flag to denote if function should get Auth0 subscriber data
 * @returns 
 */
function checkBlock(stopImmediately, setSubscriber) {
  return new Promise(async (resolve, reject) => {
    try {
      let user = lastUser;
      if (!user) {
        try {
          user = await getUser();
        } catch (e) {}
      }
      if ((setSubscriber || !subscriber) && user) {
        userId = user.sub;
        subscriber = Boolean(user.plan);
      }
      if (subscriber || currentTimeListened.listenTime < listenerCapData.listeningCapTimeLimit) {
        BLOCK = false;
        if (audioPlayer && audioPlayer.playlistId === BLOCK_PLAYLIST_ID) {
          reject("Updated block");
          newPlaylist(
            last.playlistId
              ? last.playlistId
              : "bab24f05-73f8-4088-9373-bf118f07bdec",
            last.channelId ? last.channelId : "jazzgroove-mix-1",
            false,
            true
          );
        }
      } else {
        // bugsnagClient.notify(new Error("Block Activated"), {
        //   metaData: { ...user },
        // });
        
        if (!BLOCK) {
          BLOCK = true;
          if (stopImmediately) {
            newPlaylist(BLOCK_PLAYLIST_ID, "BLOCK", false, false);
            reject("Updated block");
          } else if (audioPlayer) audioPlayer.playlistId = BLOCK_PLAYLIST_ID;
        }
      }
    } catch (e) {
      if (currentTimeListened.listenTime > maxListenTime && !subscriber) {
        // bugsnagClient.notify(new Error("Catch Block Activated"));
        BLOCK = true;
        if (audioPlayer) {
          audioPlayer.playlistId = BLOCK_PLAYLIST_ID;
        }
      }
    }
    resolve(BLOCK);
  });
}

let currentEntry = {};
let activeChannelId = "";
let audioPlayer = null;
let started = false;
let quality = [LOW_QUALITY];
let currentMetadata = {};
let currentPlaylist = "";
let lastTimes = [];
let ignoreTimeUpdates = false;
let ignoreTimeUpdateTimeout;
let eventCallbacks = {
  recentlyPlayed: null,
  newSong: null,
  statusUpdate: null,
  seeked: null,
};
let songData = {};
let trackProgress = 0;

function setIngoreTimeUpdates(bool) {
  ignoreTimeUpdates = bool;
}
function setIgnoreTimeUpdateTimeout(timeout) {
  ignoreTimeUpdateTimeout = timeout;
}

function setAudioCallbacks(callbacks) {
  eventCallbacks = { ...eventCallbacks, ...callbacks };
}

async function setMaxQuality(max) {
  switch (max) {
    case HIGH_QUALITY:
      quality = [HIGH_QUALITY, MID_QUALITY, LOW_QUALITY];
      break;
    case MID_QUALITY:
      quality = [MID_QUALITY, LOW_QUALITY];
      break;
    case LOW_QUALITY:
      quality = [LOW_QUALITY];
      break;
    default:
      quality = [LOW_QUALITY];
  }
  if (max) {
    try {
      const accessToken = await getTokenSilently({
        audience: AUTH0_MANAGEMENT_AUDIENCE,
        scope: "update:current_user_metadata",
      });
      const user = await getUser();
      const user_metadata = user[AUTH0_USER_METADATA_KEY];
      user_metadata.preferences["quality"] = max;
      await Axios.patch(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/` +
          user.sub,
        {
          user_metadata: user_metadata,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {}
  }
}

function updateNowPlaying() {
  const coverVariant = currentEntry.Asset.Variants.find((variant) => {
    return variant.type === "coverThumbnail-500x500";
  });
  songData = {
    title: currentEntry.Asset.tags.TITLE ? currentEntry.Asset.tags.TITLE : "",
    artist: currentEntry.Asset.tags.ARTIST
      ? currentEntry.Asset.tags.ARTIST
      : "",
    src: coverVariant ? coverVariant.urls.get : "",
    coverVariant: currentEntry.Asset.id.charCodeAt(0) % 4,
    progress: trackProgress,
  };
  if (eventCallbacks.nowPlayingUpdate)
    eventCallbacks.nowPlayingUpdate(songData);
}

let newPlaylist;

if (typeof AudioPump !== "undefined") {
  class LowQualityVariantResolver extends AudioPump.Channels.VariantResolver {
    static get mediaVariantTypePriority() {
      return quality;
    }
  }

  class JazzGroovePlaylistController extends AudioPump.Channels
    .SequentialPlaylistController {
    static getCurrentDate() {
      const d = new Date();
      return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
    }

    static getNext(playlist, currentEntry) {
      const next = super.getNext.apply(this, arguments);
      const tag =
        next && next.Asset && next.Asset.tags
          ? next.Asset.tags["jg-voiceover"]
          : null;
      if (lastUser) {
        if (isPremiumPlus(lastUser.plan)) {
          if (
            tag === "FD" &&
            lastUser[AUTH0_USER_METADATA_KEY].preferences.silenceFD
          ) {
            return this.getNext(playlist, next);
          } else if (
            tag === "DJ" &&
            lastUser[AUTH0_USER_METADATA_KEY].preferences.silenceDJ
          ) {
            return this.getNext(playlist, next);
          } else if (tag === "PM") {
            return this.getNext(playlist, next);
          } else if (
            tag === "V" &&
            ((lastUser[AUTH0_USER_METADATA_KEY].preferences.silenceMix2Vocals &&
              activeChannelId === "jazzgroove-mix2") ||
              (lastUser[AUTH0_USER_METADATA_KEY].preferences
                .silenceSmoothVocals &&
                activeChannelId === "jazzgroove-smooth"))
          ) {
            return this.getNext(playlist, next);
          }
        } else if (isPremium(lastUser.plan)) {
          if (tag === "PM") {
            return this.getNext(playlist, next);
          } else if (tag === "FD" && true) {
            return this.getNext(playlist, next);
          }
        }
      }
      return next;
    }
  }

  function ended() {
    switch (activeChannelId) {
      case "jazzgroove-mix1":
        audioPlayer.playlistId = "03475f49-5b46-46f1-a3d4-2e4d3fc0e8f7";
        break;
      case "jazzgroove-mix2":
        audioPlayer.playlistId = "c1115e6b-38ad-44ef-8dcf-df5c2f65ed60";
        break;
      case "jazzgroove-dreams":
        audioPlayer.playlistId = "08f64a07-748f-4862-9ec6-0c2f5380c05f";
        break;
      case "jazzgroove-gems":
        audioPlayer.playlistId = "4fdf2e49-6a93-4a32-bd17-16aa8ff87713";
        break;
      case "jazzgroove-smooth":
        audioPlayer.playlistId = "ced5de56-84f3-423d-94cf-88bb28f19396";
        break;
      default:
        throw new Error("Invalid channel id");
    }
  }

  function onError(e) {
    // Ignore abort errors... they usually happen due to a legitimate user action.
    if (audioPlayer.error.name === "AbortError") {
      return;
    }

    // Errors sometimes occur in clusters.  For example, if connectivity is lost,
    // there may be several simultaneous or near-simultaneous errors triggered
    // on multiple HTMLMediaElements, and potentially in playlist loading.
    // After an error occurs, ignore subsequent errors until we retry playing.
    if (awaitingRetry) {
      return;
    }

    if (audioPlayer.error) {
      Sentry.captureException(new Error("Audio Player Error"), {
        user: lastUser ? {
          id: lastUser.sub,
          email: lastUser.email
        } : undefined,
        extra: {
          errorType: e && e.constructor ? e.constructor.name : undefined,
          currentTrack:
            currentEntry.Asset && currentEntry.Asset.tags.TITLE
              ? currentEntry.Asset.tags.TITLE
              : "",
          quality: lastUser ? lastUser[AUTH0_USER_METADATA_KEY].preferences.quality : LOW_QUALITY,
          retryCount: retryCount
        },
        tags: {
          message: audioPlayer.error.message,
          code: audioPlayer.error.code
        }
      });
      audioPlayer.error = undefined;
    } else {
      Sentry.captureException(e, {
        user: lastUser ? {
          id: lastUser.sub,
          email: lastUser.email
        } : undefined,
      });
    }

    // Limit the number of attempts to retry playback.
    if (retryCount >= retryLimit) {
      // Consider alerting the user here.
      // Future attempts to play should involve a full reinstantiation of the Player
      // as a catch-all to any corrupted state that may be causing the problem.
      console.error("Player Error", { error: audioPlayer.error });
      return;
    }

    retryCount++;

    if (retryCount <= 1) {
      // This is the first error occurence.  Try to recover immediately
      // by skipping everything in the current queue.
      audioPlayer.seekQueueRelative(audioPlayer.queue.length);
    } else {
      // If this is a subsequent error, attempt recovery only after a delay.
      // Note: Due to varying implementations of autoplay policy, not all browsers
      // are able to start playback after such a delay without user interaction.
      awaitingRetry = true;
      setTimeout(() => {
        awaitingRetry = false;
        audioPlayer.seekQueueRelative(audioPlayer.queue.length);
      }, retryCount * 1000);
    }

    ReactGA.event({
      category: "player",
      action: "retry",
      value: retryCount,
    });
  }

  function addListeners() {
    audioPlayer.addEventListener("queueupdate", queueUpdate);
    audioPlayer.addEventListener("timeupdate", timeUpdate);
    audioPlayer.addEventListener("play", eventCallbacks.statusUpdate);
    audioPlayer.addEventListener("pause", eventCallbacks.statusUpdate);
    audioPlayer.addEventListener("seeked", eventCallbacks.seeked);
    audioPlayer.addEventListener("ended", ended);
    audioPlayer.addEventListener("error", onError);
  }

  function removeListeners() {
    audioPlayer.removeEventListener("queueupdate", queueUpdate);
    audioPlayer.removeEventListener("timeupdate", timeUpdate);
    audioPlayer.removeEventListener("play", eventCallbacks.statusUpdate);
    audioPlayer.removeEventListener("pause", eventCallbacks.statusUpdate);
    audioPlayer.removeEventListener("seeked", eventCallbacks.seeked);
    audioPlayer.removeEventListener("ended", ended);
    audioPlayer.removeEventListener("error", onError);
  }

  function timeUpdate(e) {
    if (audioPlayer.seeking || ignoreTimeUpdates) return;
    lastTimes.push(e.target.currentTime);
    while (lastTimes.length > 2) {
      lastTimes.shift();
    }
    currentMetadata.playTime = e.target.currentTime;
    const currentProgress =
      (e.target.currentTime / currentEntry.Asset.effectiveDuration) * 100;

    if (trackProgress + 1 < currentProgress) {
      trackProgress = currentProgress;
      updateNowPlaying();
    } else if (trackProgress > currentProgress) {
      trackProgress = 0;
      updateNowPlaying();
    }
  }

  function queueUpdate(e) {
    const entries = e.detail;
    // Show "now playing" information
    if (entries[0]) {
      if (
        subscriber &&
        entries[0].id === "cb80a647-0d8b-4a8a-a534-4241f9a90da5" &&
        audioPlayer.playlistId !== BLOCK_PLAYLIST_ID
      ) {
        audioPlayer.seekQueueRelative(1);
        return;
      }
      if (!started) {
        currentEntry = entries[0];
        currentMetadata = {
          tags: currentEntry.Asset.tags,
          startTime: new Date().toISOString(),
          endTime: null,
          playTime: 0,
          playlistId: audioPlayer.playlistId,
          channelId: activeChannelId,
          assetId: currentEntry ? currentEntry.Asset.id : null,
          effectiveDuration: currentEntry.Asset.effectiveDuration,
          title: currentEntry.Asset.title,
          userId: userId,
          isSubscriber: subscriber,
        };
        sendLog("start", currentMetadata);
        started = true;
        if ("mediaSession" in navigator) {
          const coverVariant = currentEntry.Asset.Variants.find((variant) => {
            return variant.type === "coverThumbnail-500x500";
          });
          /* global MediaMetadata */
          navigator.mediaSession.metadata = new MediaMetadata({
            title: currentEntry.Asset.tags.TITLE
              ? currentEntry.Asset.tags.TITLE
              : "",
            artist: currentEntry.Asset.tags.ARTIST
              ? currentEntry.Asset.tags.ARTIST
              : "",
            artwork: coverVariant
              ? [
                  {
                    src: coverVariant.urls.get,
                    sizes: "500x500",
                    type: "image/jpg",
                  },
                ]
              : undefined,
          });
        }
      }
      if (currentMetadata.assetId !== entries[0].Asset.id) {
        sendLog("stop", {
          ...currentMetadata,
          endTime: new Date().toISOString(),
        });
        addToRecentlyPlayed(currentEntry.Asset);
        currentEntry = entries[0];
        currentMetadata = {
          tags: currentEntry.Asset.tags,
          startTime: new Date().toISOString(),
          endTime: null,
          playTime: 0,
          playlistId: audioPlayer.playlistId,
          channelId: activeChannelId,
          assetId: currentEntry ? currentEntry.Asset.id : null,
          effectiveDuration: currentEntry.Asset.effectiveDuration,
          title: currentEntry.Asset.title,
          userId: userId,
          isSubscriber: subscriber,
        };
        sendLog("start", currentMetadata);
      }
      updateNowPlaying();
    }
  }

  if (!localStorage.getItem("session"))
    localStorage.setItem(
      "session",
      Math.random().toString(36).substr(2) +
        Math.random().toString(36).substr(2)
    );

  document.addEventListener("DOMContentLoaded", (e) => {
    window.addEventListener("beforeunload", () => {
      sendLog("stop", {
        ...currentMetadata,
        endTime: new Date().toISOString(),
      });
    });
  });

  newPlaylist = async function (playlistId, channelId, external, paused) {
    try {
      let play = false;
      if (audioPlayer && !paused) {
        audioPlayer.pause();
        removeListeners();
        play = true;
      }
      if (!BLOCK && audioPlayer && audioPlayer.playlistId === BLOCK_PLAYLIST_ID)
        await checkBlock(true, true);
      if (playlistId !== "d87f1b64-a00f-4915-a34e-5fa21d58813c") {
        last = {
          playlistId: playlistId,
          channelId: channelId,
        };
      }
      activeChannelId = channelId;
      audioPlayer = new AudioPump.Channels.Player({
        playlistId: BLOCK ? "d87f1b64-a00f-4915-a34e-5fa21d58813c" : playlistId,
        baseUrl:
          "https://tjg-apchannels-media-prod.sfo2.cdn.digitaloceanspaces.com/",
        playlistController: JazzGroovePlaylistController,
        variantResolver: LowQualityVariantResolver,
      });
      currentPlaylist = BLOCK
        ? "d87f1b64-a00f-4915-a34e-5fa21d58813c"
        : playlistId;
      started = false;
      audioPlayer.load();
      addListeners();

      if (play && !external) {
        audioPlayer.play();

        ReactGA.event({
          category: "player",
          action: "streamquality",
          label: activeChannelId,
          value: quality,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  function addToRecentlyPlayed(song) {
    let variant = song.Variants.find((variant) => {
      return variant.type === "coverThumbnail-500x500";
    });
    if (!variant) return;
    let recentlyPlayed;
    if (localStorage.getItem("recently-played")) {
      recentlyPlayed = JSON.parse(localStorage.getItem("recently-played"));
    } else {
      recentlyPlayed = {
        "jazzgroove-mix1": [
          {
            title: "Chitlins Con Carne",
            img: "/album-art/Kenny-Burrell-Chitlins-Con-Carne.jpg",
            artist: "Kenny Burrell",
            album: "",
            url: "",
          },
          {
            title: "Stairway To The Stars",
            img: "/album-art/Dexter-Gordon-Stairway-To-The-Stars.jpg",
            artist: "Dexter Gordon",
            album: "",
            url: "",
          },
          {
            title: "Cool Struttin",
            img: "/album-art/Sonny-Clark-Cool-Struttin.jpg",
            artist: "Sonny Clark",
            album: "",
            url: "",
          },
          {
            title: "Blues To Elvin",
            img: "/album-art/John-Coltrane-Blues-To-Elvin.jpg",
            artist: "John Coltrane",
            album: "",
            url: "",
          },
          {
            title: "Mood Indigo",
            img: "/album-art/Duke-Ellington-Mood-Indigo.jpg",
            artist: "Duke Ellington",
            album: "",
            url: "",
          },
          {
            title: "Timelessness ",
            img: "/album-art/Wynton-Marsalis-Timeless.jpg",
            artist: "Wynton Marsalis",
            album: "",
            url: "",
          },
        ],
        "jazzgroove-mix2": [
          {
            title: "Alone Together",
            img: "/album-art/Chet-Baker-Alone-Together.jpg",
            artist: "Chet Baker",
            album: "",
            url: "",
          },
          {
            title: "Take Five",
            img: "/album-art/Dave-Brubeck-Take-Five.jpg",
            artist: "Dave Brubeck",
            album: "",
            url: "",
          },
          {
            title: "Cinema Paradiso",
            img: "/album-art/Pat-Metheny-Cinema-Paradiso.jpg",
            artist: "Pat Metheny",
            album: "",
            url: "",
          },
          {
            title: "Corcovado",
            img: "/album-art/Stan-Getz-Joao-Gilberto-Corcovado.jpg",
            artist: "Stan Getz & Joao Gilberto",
            album: "",
            url: "",
          },
          {
            title: "I'm Old Fashioned",
            img: "/album-art/John-Coltrane-Im-Old-Fashioned.jpg",
            artist: "John Coltrane",
            album: "",
            url: "",
          },
          {
            title: "Blue In Green",
            img: "/album-art/Miles-Davis-Blue-In-Green.jpg",
            artist: "Miles Davis",
            album: "",
            url: "",
          },
          {
            title: "Autumn Leaves",
            img: "/album-art/Cannonball-Adderley-Autumn-Leaves.jpg",
            artist: "Cannonball Adderley",
            album: "",
            url: "",
          },
        ],
        "jazzgroove-dreams": [
          {
            title: "Serene",
            img: "/album-art/Wes-Montgomery-Serene.jpg",
            artist: "Wes Montgomery",
            album: "",
            url: "",
          },
          {
            title: "Bon Ami",
            img: "/album-art/Bill-Charlap-Trio-Bon-Ami.jpg",
            artist: "Bill Charlap Trio",
            album: "",
            url: "",
          },
          {
            title: "Nightfall",
            img: "/album-art/Til-Bronner-Nightfall.jpg",
            artist: "Til Bronner",
            album: "",
            url: "",
          },
          {
            title: "Curves",
            img: "/album-art/Tord-Gustavsen-Curves.jpg",
            artist: "Tord Gustavsen",
            album: "",
            url: "",
          },
          {
            title: "Stella By Starlight",
            img: "/album-art/Ron-Carter-Stella-By-Starlight.jpg",
            artist: "Ron Carter",
            album: "",
            url: "",
          },
        ],
        "jazzgroove-gems": [
          {
            title: "Just In Time",
            img: "/album-art/Frank-Sinatra-Just-In-Time.jpg",
            artist: "Frank Sinatra",
            album: "",
            url: "",
          },
          {
            title: "Walkin'",
            img: "/album-art/Nat-King-Cole-Walkin.jpg",
            artist: "Nat King Cole",
            album: "",
            url: "",
          },
          {
            title: "Get Happy",
            img: "/album-art/Ella-Fitzgerald-Get-Happy.jpg",
            artist: "Ella Fitzgerald",
            album: "",
            url: "",
          },
          {
            title: "Fever",
            img: "/album-art/Ray-Charles-Natalie-Cole-Fever.jpg",
            artist: "Ray Charles & Natalie Cole",
            album: "",
            url: "",
          },
          {
            title: "'S Wonderful",
            img: "/album-art/Diana-Krall-S-Wonderful.jpg",
            artist: "Diana Krall",
            album: "",
            url: "",
          },
        ],
        "jazzgroove-smooth": [
          {
            title: "Boney James",
            img: "/album-art/Boney-James-Body-Language.jpg",
            artist: "Body Language",
            album: "",
            url: "",
          },
          {
            title: "Thinking About You",
            img: "/album-art/Norman-Brown-Thinking-About-You.jpg",
            artist: "Norman Brown",
            album: "",
            url: "",
          },
          {
            title: "Winelight",
            img: "/album-art/Grover-Washington-Jr-Winelight.jpg",
            artist: "Grover Washington Jr.",
            album: "",
            url: "",
          },
          {
            title: "The Joy Of Life",
            img: "/album-art/Kenny-G-The-Joy-Of-Life.jpg",
            artist: "Kenny G",
            album: "",
            url: "",
          },
          {
            title: "Kiss Of Life",
            img: "/album-art/Sade-Kiss-Of-Life.jpg",
            artist: "Sade",
            album: "",
            url: "",
          },
        ],
      };
      localStorage.setItem("recently-played", JSON.stringify(recentlyPlayed));
    }
    if (
      recentlyPlayed[activeChannelId] &&
      recentlyPlayed[activeChannelId].length >= 30
    ) {
      recentlyPlayed[activeChannelId].shift();
    }
    const currentSong = {
      title: song.tags.TITLE,
      img: variant.urls.get,
      artist: song.tags.ARTIST,
      album: song.tags.ALBUM,
      url: "",
    };
    if (
      recentlyPlayed[activeChannelId].slice(-1)[0].title !== currentSong.title
    )
      recentlyPlayed[activeChannelId].push(currentSong);
    localStorage.setItem("recently-played", JSON.stringify(recentlyPlayed));
    eventCallbacks.recentlyPlayed(recentlyPlayed[activeChannelId]);
  }
}

export {
  audioPlayer,
  setMaxQuality,
  started,
  newPlaylist,
  updateNowPlaying,
  setAudioCallbacks,
  activeChannelId,
  currentEntry,
  lastTimes,
  setIngoreTimeUpdates,
  ignoreTimeUpdateTimeout,
  setIgnoreTimeUpdateTimeout,
  incrementMinutes,
  checkBlock,
  BLOCK,
  currentMetadata,
  currentPlaylist,
  songData,
};
