import React from "react";
import { activeChannelId, audioPlayer } from "AudioPlayer";
import { BackgroundImageWithLightbox } from "../ImageWithLightbox";
import Slider from "rc-slider";

export const Song = props => {
  return (
    <div className={props.mobile ? "mobile-song" : "song"}>
      <BackgroundImageWithLightbox
        className={"song-image " + activeChannelId}
        onClick={props.toggleMobileShow}
        src={props.src}
      />
      <div className="song-data">
        <Slider className="track-progress" value={props.progress} />
        <p className="song-name">{props.title}</p>
        <p className="song-artist">{props.artist}</p>
      </div>

      {props.mobile && (
        <div className="controls">
          <button onClick={props.handlePlay} aria-label="Play">
            {props.paused ? (
              <i className="fas fa-play"></i>
            ) : (
              <i className="fas fa-pause"></i>
            )}
          </button>
          {audioPlayer && (
            <button onClick={props.skipSong} aria-label="Play">
              <i className="fas fa-step-forward"></i>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Song;
