import React, { useState, Fragment, useEffect, useCallback } from "react";
import * as Display from "Utils/Display";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#276db2",
    },
    secondary: {
      main: "#adcff2",
    },
  },
});

const themecheck = createMuiTheme({
  palette: {
    primary: {
      main: "#276db2",
    },
    secondary: {
      main: "#276db2",
    },
  },
});

export function getDonationPlan(monthly, amount) {
  if (monthly) {
    if (amount >= 1000) {
      return "premium-plus-monthly";
    } else if (amount >= 500) {
      return "jazzgroove-premium-monthly";
    } else {
      return "unlimited-listening";
    }
  } else {
    if (amount >= 10000) {
      return "premium-plus";
    } else if (amount >= 6000) {
      return "jazzgroove-premium";
    } else {
      return "unlimited-listening";
    }
  }
}
const SelectAmount = (props) => {
  const [customAmount, setCustomAmount] = useState(false);
  const [error, setError] = useState("");

  // Sets the props.amount value (which triggers the useEffect) when an amount bubble selected
  // in the UI or a custom amount is inputted.
  function setAmount(amount) {
    props.setAmount(parseInt(amount));
  }

  // Handles changes to the custom dollar amount input field
  function handleChange(e) {
      setCustomAmount(true);
      /*eslint-disable-next-line no-useless-escape*/
      const value = e.target.value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
      const customValue = (value * 100);
      // If not a number, ignore change by returning
      if (isNaN(customValue)) return;
      // Removes the gift selected if the entered custom amount
      // is less than the monthly/one-time price of the item respectively
      if (props.monthly && props.giftDetails.meta_data.pricing) {
        if (props.giftDetails.meta_data.pricing.monthly.minimum > customValue) props.resetGift();
      } else if (!props.monthly && props.giftDetails.meta_data.pricing){
        if (props.giftDetails.meta_data.pricing.oneTime.minimum > customValue) props.resetGift(); //prop from Checkout.js
      } else {
        // when no gift is selected, as it does not have a price
      };
      setAmount(customValue);
  }
  
  const getAmounts = useCallback(
    function getAmounts() {
      if (props.monthly) return [500, 1000, 1500, 2000, 2500, 5000, 10000];
      else if (props.isGift) return [10000, 14500, 25000, 50000, 75000, 100000];
      else return [6000, 7500, 10000, 14500, 25000, 50000, 75000, 100000];
    },
    [props.monthly, props.isGift]
  );

  // Runs when the props.amount value changes, which occurs
  // either when the SetAmount function in this component runs
  // or when the prop is passed from another component such as Checkout.js when
  // a gift in the UI is selected
  useEffect(() => {
    // Sets the amount to the custom field if that amount is not a preset bubble
    // amount set in getAmounts()
    if (getAmounts().indexOf(props.amount) === -1) setCustomAmount(true);
    else setCustomAmount(false);
    // Set error message if gift membership is selected and amount is less than $100
    if (props.isGift && props.amount < 10000) {
      setError("Gift memberships require $100 minimum");
    // Set error message if donation amount is less than $5
    } else if (props.amount < 500) {
      setError("The amount entered is less than the minimum donation amount of $5")
    // Else don't set an error message
    } else {
      setError("")
    }
    //eslint-disable-next-line
  }, [props.amount]);

  return (
    <Fragment>
      <div className="select-donation-container">
        <form className="radio-button-group">
          <Tab
            active={props.monthly}
            title="Monthly Donation"
            onClick={() => {
              props.setMonthly(true);
              setAmount(1000);
              props.setIsGift(false);
              setCustomAmount(false);
              setError("");
              props.resetGift();
            }}
          />
          <Tab
            active={!props.monthly && !props.isGift}
            title="One-time Donation"
            onClick={() => {
              props.setMonthly(false);
              setAmount(10000);
              props.setIsGift(false);
              setCustomAmount(false);
              setError("");
              props.resetGift();
            }}
          />
          <Tab
            active={props.isGift}
            title="Gift Membership"
            onClick={() => {
              props.setMonthly(false);
              setAmount(10000);
              props.setIsGift(true);
              setCustomAmount(false);
              setError("");
              props.resetGift();
            }}
          />
        </form>

        <div className="bubbles-container">
          {getAmounts().map((amount, index) => {
            return (
              <button
                aria-label={`${
                  props.monthly ? "One-time donation" : "Monthly plan"
                } of $ + ${amount / 100}`}
                key={index}
                className={
                  props.amount === amount && !customAmount
                    ? "bubble selected"
                    : "bubble"
                }
                onClick={() => {
                  if (props.amount > amount) props.resetGift();
                  setAmount(amount);
                }}
              >
                ${amount / 100}
              </button>
            );
          })}
          <button className={customAmount ? "bubble selected" : "bubble"}>
            $
            <input
              aria-label="custom-donation"
              className="custom-donation-input"
              type="text"
              placeholder="Custom"
              value={
                customAmount ? Display.formatDonation(props.amount / 100) : ""
              }
              onChange={handleChange}
              maxLength={13}
            />
          </button>
        </div>
      </div>

      {error && <p className="form-warning-message">{error}</p>}

      {props.isGift ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const { first_name, last_name, email } = props.giftRecipient;
            if (!error && first_name && last_name && email)
              props.sendToPayment();
          }}
        >
          <div>
            <hr className="divider" />
            <div className="multiple-input-row">
              <label htmlFor="recipient-fName">
                Recipient First Name
                <input
                  type="text"
                  id="recipient-fName"
                  name="recipient-fName"
                  value={props.giftRecipient.first_name}
                  onChange={(e) => {
                    const first_name = e.target.value;
                    props.setGiftRecipient((r) => {
                      return { ...r, first_name: first_name };
                    });
                  }}
                  required
                />
              </label>
              <label htmlFor="recipient-lName">
                Recipient Last Name
                <input
                  type="text"
                  id="recipient-lName"
                  name="recipient-lName"
                  value={props.giftRecipient.last_name}
                  onChange={(e) => {
                    const last_name = e.target.value;
                    props.setGiftRecipient((r) => {
                      return { ...r, last_name: last_name };
                    });
                  }}
                  required
                />
              </label>
            </div>
            <label htmlFor="recipient-email">
              Recipient Email
              <input
                type="email"
                id="recipient-email"
                name="recipient-email"
                value={props.giftRecipient.email}
                onChange={(e) => {
                  const email = e.target.value;
                  props.setGiftRecipient((r) => {
                    return { ...r, email: email.toLowerCase() };
                  });
                }}
                required
              />
            </label>
          </div>
          <label htmlFor="recipient-note">
            Note to Recipient <p className="tiny">(500 character max)</p>
            <textarea
              className="recipient-note"
              id="recipient-note"
              name="recipient-note"
              value={props.giftRecipient.note}
              maxLength={500}
              onChange={(e) => {
                const note = e.target.value;
                props.setGiftRecipient((r) => {
                  return { ...r, note: note };
                });
              }}
            ></textarea>
          </label>
          <div className="send-now">
            <label htmlFor="send-now">
              {" "}
              Send now
              <ThemeProvider theme={themecheck}>
                <Checkbox
                  id="send-now"
                  name="send-now"
                  className="send-checkbox"
                  defaultChecked={true}
                  onChange={(e) => {
                    const sendNow = e.target.checked;
                    props.setGiftRecipient((r) => {
                      return { ...r, sendNow: sendNow };
                    });
                  }}
                />
              </ThemeProvider>
            </label>
          </div>
          <div>
            <label htmlFor="time-picker" className="date-picker-label">
              Select Date/Time To Send Gift Membership
              <div className="date-picker">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ThemeProvider theme={theme}>
                    <DateTimePicker
                      disablePast={true}
                      disabled={Boolean(props.giftRecipient.sendNow)}
                      value={
                        props.giftRecipient.sendNow
                          ? moment()
                          : moment(
                              props.giftRecipient.date &&
                                props.giftRecipient.date * 1000
                            )
                      }
                      onChange={(date) =>
                        props.setGiftRecipient((r) => {
                          return {
                            ...r,
                            date: date.isSameOrAfter(moment())
                              ? date.unix()
                              : moment().unix(),
                          };
                        })
                      }
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </div>
            </label>
          </div>
          <button className="button-primary">Continue to Payment</button>
        </form>
      ) : (
        <button
          className="button-primary"
          onClick={() => {
            if (!error) props.sendToPayment();
          }}
        >
          Continue to Payment
        </button>
      )}
    </Fragment>
  );
};

const Tab = (props) => {
  return (
    <label className={props.active ? "tab-selected" : "tab-not-selected"}>
      <input
        className={"with-gap"}
        type={"radio"}
        name={"monthly"}
        value={0}
        checked={props.active}
        onChange={() => {}}
        onClick={props.onClick}
      />
      <span>
        <p className="tab-title">{props.title}</p>
      </span>
    </label>
  );
};

export default SelectAmount;
